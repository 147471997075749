import { useConfig } from "../../context/AdminContext";
import "./floatbutton.css"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function obtenerNumeros(cadena: string): string {
    let numeros = "";
    for (let i = 0; i < cadena.length; i++) {
        if (!isNaN(parseInt(cadena[i]))) {
            numeros += cadena[i];
        }
    }
    return numeros;
}

const WpButton = () => {
    const { config } = useConfig()
    return (
        <a href={`https://wa.me/${obtenerNumeros(config?.contact?.phone ?? "")}`}>
            <div className="floatButton wp">
                <WhatsAppIcon sx={{ width: "60%", height: "100%",color:"white" }} />
            </div>
        </a>
    )
}

export default WpButton