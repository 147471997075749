import { useState } from "react";
import { Availability } from "../../../typings/Availability";
import ModalDay from "../modals/ModalDay";
import ModalDelete from "../modals/ModalDelete"
import ModalEdit from "../modals/ModalEdit"
import ModalEditNews from "../modals/ModalEditNews";
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, TextField } from "@mui/material";
import { useConfig } from "../../../context/AdminContext";
import CheckIcon from '@mui/icons-material/Check';
import axios from "axios";
import { config } from '../../../config';

const { backendEndpoint } = config;


interface Props {
    name?: string
    url?: string
    prop?: string
    text?: any
    isSlider?: boolean
    day?: keyof Availability,
    content?: string
}

const days = {
    "monday": "Lunes",
    "tuesday": "Martes",
    "wednesday": "Miércoles",
    "thursday": "Jueves",
    "friday": "Viernes",
    "saturday": "Sábado",
    "sunday": "Domingo"
}

export const ImageRow = ({ name, url, prop, isSlider }: Props) => {
    return (
        <div className="editorRow">
            <span className="editorRowItem">{name}</span>
            <img className="editorRowItem" src={url} alt={`preview-${name}`} />
            <div className="editorRowItem buttonContainer">
                <ModalEdit prop={prop!} url={url} isSlider={isSlider} />
                {isSlider &&
                    <ModalDelete prop={prop!} />
                }
            </div>
        </div >
    )
}


export const TextRow = ({ name, text, prop }: Props) => {
    return (
        <div className="editorRow">
            <span className="editorRowItem">{name}</span>
            <span className="editorRowItem">{text}</span>
            <div className="editorRowItem buttonContainer">
                <ModalEdit prop={prop!} text={text} />
            </div>
        </div>
    )
}

export const DayRow = ({ day }: Props) => {
    return (
        <div className="editorRow">
            <span className="editorRowItem">{days[day as keyof typeof days]}</span>
            <span className="editorRowItem"></span>
            <div className="editorRowItem buttonContainer">
                <ModalDay day={day!} />
            </div>
        </div>
    )
}

export const NewsRow = ({ name, url, content, prop, isSlider }: Props) => {
    return (
        <div className="editorRow">
            <span className="editorRowItem">{name}</span>
            <img className="editorRowItem" src={url} alt={`preview-${name}`} />
            <div className="editorRowItem buttonContainer">
                <ModalEdit prop={prop!} url={url} isSlider={isSlider} isNews />
                <ModalEditNews name={name!} content={content!} prop={prop!} />
                {isSlider &&
                    <ModalDelete prop={prop!} />
                }
            </div>
        </div>
    )
}

export const PriceRow = ({ name, text }: Props) => {
    const [editable, setEditable] = useState(false)
    const { config, setConfig, setNewConfig } = useConfig()
    const [value, setValue] = useState<Number | null>(config?.reservationPrice ?? 0)
    const [loading, setLoading] = useState(false)
    return (
        <div className="editorRow">
            <span className="editorRowItem">{name}</span>
            {!editable ?
                <span className="editorRowItem">{text}</span>
                :
                <TextField
                    id="outlined"
                    value={value}
                    onChange={(e) => {
                        let val = e.target.value;
                        if (val === "") {
                            setValue(null);
                            return;
                        }
                        if (!Number.isNaN(parseInt(val))) {
                            setValue(parseInt(val, 10));
                        }
                    }}
                />
            }
            <div className="editorRowItem buttonContainer">
                <button onClick={() => {
                    if (loading) return
                    if (editable && Number(value)) {
                        setLoading(true)
                        axios.put(`${backendEndpoint}/`, { ...config, reservationPrice: value }).then(() => {
                            axios.get(`${backendEndpoint}/`).then((res) => {
                                setConfig(res.data)
                                setNewConfig(res.data)
                                setEditable(false)
                                setLoading(false)
                            })
                        })
                    } else setEditable(true)
                }} className="editItem">

                    {!editable ? <EditIcon /> : (!loading ? <CheckIcon /> : <div className="center"><CircularProgress size={20} sx={{ color: "black" }} /></div>)}
                </button>
            </div>
        </div>
    )
}