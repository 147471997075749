import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { Appointment } from '../typings/Appointment';

// Definir el tipo para el estado del contexto
interface GestionContextType {
  monthAppointments: Appointment[];
  setMonthAppointments: React.Dispatch<React.SetStateAction<Appointment[]>>;
  detail: {
    open: boolean;
    appointment: Appointment | undefined
  }
  setDetail: React.Dispatch<React.SetStateAction<any>>;
}

// Crear el contexto
const GestionContext = createContext<GestionContextType | undefined>(undefined);

// Crear el proveedor del contexto
interface GestionProviderProps {
  children: ReactNode;
}

const GestionProvider: React.FC<GestionProviderProps> = ({ children }) => {
  const [monthAppointments, setMonthAppointments] = useState<Appointment[]>([]);
  const [detail, setDetail] = useState(
    {
      open: false,
      appointment: undefined
    }
  )

  // Puedes agregar lógica de efecto aquí si es necesario
  useEffect(() => {
    // Por ejemplo, cargar las citas del mes al montar el componente
    // fetchMonthAppointments().then(appointments => setMonthAppointments(appointments));
  }, []);

  return (
    <GestionContext.Provider value={{ monthAppointments, setMonthAppointments, detail, setDetail }}>
      {children}
    </GestionContext.Provider>
  );
};

// Crear un hook personalizado para usar el contexto
const useGestion = () => {
  const context = useContext(GestionContext);
  if (!context) {
    throw new Error('useGestion debe ser utilizado dentro de un GestionProvider');
  }
  return context;
};

export { GestionProvider, useGestion };
