import React, { useEffect, useState } from "react";
import "./month.css";
import {
  format,
  addMonths,
  startOfMonth,
  startOfWeek,
  eachDayOfInterval,
  isSameDay,
} from "date-fns";
import axios from "axios";
import { config } from "../../../../config"
import { useGestion } from "../../../../context/GestionContext";
import { useConfig } from "../../../../context/AdminContext";
import { Availability } from "../../../../typings/Availability";

const { backendEndpoint } = config;

interface MonthProps {
  currentDate: Date;
  onDayClick: (selectedDate: Date) => void;
}

const Month: React.FC<MonthProps> = ({ currentDate, onDayClick }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { monthAppointments, setMonthAppointments } = useGestion()
  const { availability } = useConfig()
  const bans = availability?.bans
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear().toString();

  useEffect(() => {
    axios(`${backendEndpoint}/appointment/${month}${year}`).then(res => setMonthAppointments(res.data))
    //eslint-disable-next-line
  }, [currentDate])

  const renderMonthView = () => {
    const startOfMonthDate = startOfMonth(currentDate);
    const startDayOfWeek = startOfWeek(startOfMonthDate);
    const endOfMonthDate = addMonths(startOfMonthDate, 1);
    const daysOfMonth = eachDayOfInterval({
      start: startDayOfWeek,
      end: endOfMonthDate,
    });

    return (
      <div className="monthContainer">
        {daysOfMonth.map((day) => {
          const dayOfWeek = day.toLocaleString("en-US", { weekday: "long" }).toLowerCase();
          const isBanned = bans?.some(ban => format(day, 'dd/MM/yyyy') === ban)
          const isUnavailable = availability && availability[dayOfWeek as keyof Availability].length === 0
          const isCurrentMonth = day.getMonth() === startOfMonthDate.getMonth();
          const isToday = isSameDay(day, new Date());
          const isSelected = selectedDate && isSameDay(day, selectedDate);
          const hasNumber = isCurrentMonth && day.getDate() > 0;

          const appointmentOfDay = monthAppointments.filter((appo) => {
            const appointmentDate = new Date(appo.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
            return appointmentDate.getTime() === day.getTime();
          }).length

          return (
            <div
              key={day.getTime()}
              className={`day ${isCurrentMonth ? "" : "outside-month"} ${isToday ? "today" : ""
                } ${isSelected ? "selected" : ""} ${hasNumber && !isUnavailable ? "has-number" : "no-number"
                } ${isBanned ? "banned" : ""}`}
              onClick={() => handleDayClick(day)}
            >
              {hasNumber && (
                <div className="content-day">
                  <span className="number">{format(day, "dd")}</span>
                  {<span className="turn">{isUnavailable ? "" : (!isBanned ? appointmentOfDay + " Turnos" : "Exc")} </span>}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const handleDayClick = (day: Date) => {
    setSelectedDate(day);
    onDayClick(day);
  };

  return (
    <div className="month">
      <span className="monthWeek">
        {[
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
        ].map((day) => (
          <span key={day} className="dayMonth">
            {day}
          </span>
        ))}
      </span>
      {renderMonthView()}
    </div>
  );
};

export default Month;
