import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import "./rotate.css"

const RotateScreen = () => {
    return (
        <div className='rotateContainer'>
            <span>Por favor rotar la pantalla o cambiar el dispositivo para acceder a todas las funcionalidades.</span>
            <div className='rotateIcon '>
                <ScreenRotationIcon />
            </div>
        </div>
    )
}

export default RotateScreen