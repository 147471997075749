import React, { useEffect } from "react";
import ReactMarkdown from 'react-markdown';
import { useConfig } from "../../context/AdminContext";
import "./about.css"

const About = () => {
    const { config } = useConfig()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div>
                <span>

                </span>
                {
                    config?.aboutUs.map((item, index) => (
                        <div key={`item-key-${index}`} className="about-item" style={{ flexDirection: `${index % 2 === 0 ? "row" : "row-reverse"}` }}>
                            <ReactMarkdown className="about-text">{item.content}</ReactMarkdown>
                            <div className="about-img">
                                <img src={item.imgUrl} alt={`item-img-${index}`} />
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default About