import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import { Alert, AlertColor, Checkbox, Divider, FormControlLabel } from '@mui/material';
import { useConfig } from '../../../context/AdminContext';
import { Availability } from '../../../typings/Availability';
import { style } from '../utils/modalStyle';
import axios from 'axios';
import { config } from '../../../config';

const { backendEndpoint } = config;

interface Props {
    day: keyof Availability
}

function generateTimeSlots(start: string, end: string, interval: number) {
    var startTime = new Date("2022-01-01 " + start);
    var endTime = new Date("2022-01-01 " + end);
    var timeSlots = [];
    timeSlots.push(start);

    while (startTime < endTime) {
        startTime = new Date(startTime.getTime() + interval * 60000);

        if (startTime <= endTime) {
            var newTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            timeSlots.push(newTime);
        }
    }

    return timeSlots;
}

function calculateAppointmentInterval(timeArray: string[]): number {
    const firstTime: string = timeArray[0];
    const secondTime: string = timeArray[1];

    const initialTime: Date = new Date(`1970-01-01T${firstTime}:00Z`);
    const secondTimeDate: Date = new Date(`1970-01-01T${secondTime}:00Z`);

    const timeDifferenceInMinutes: number = (secondTimeDate.getTime() - initialTime.getTime()) / (1000 * 60);

    return timeDifferenceInMinutes;
}

function removeDuplicates(array: any[]) {
    const uniqueSet = new Set<string>(array);

    const uniqueArray = [...uniqueSet];

    return uniqueArray;
}

export default function ModalDay({ day }: Props) {
    const { availability, setAvailability } = useConfig()
    const [open, setOpen] = useState(false);
    const morningSchedules = availability![day].filter((day) => Number(day.split(":")[0]) < 12)
    const afternoonSchedules = availability![day].filter((day) => Number(day.split(":")[0]) >= 12)
    const [beginMorning, setBeginMorning] = useState<Dayjs | null>(dayjs(new Date().toJSON()));
    const [endMorning, setEndMorning] = useState<Dayjs | null>(dayjs(new Date().toJSON()));
    const [beginAfternoon, setBeginAfternoon] = useState<Dayjs | null>(dayjs(new Date().toJSON()));
    const [endAfternoon, setEndAfternoon] = useState<Dayjs | null>(dayjs(new Date().toJSON()));
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState(10)
    const [morningActive, setMorningActive] = useState(morningSchedules?.length ? true : false)
    const [afternoonActive, setAfternoonActive] = useState(afternoonSchedules?.length ? true : false)
    const [alert, setAlert] = useState("hidden")

    useEffect(() => {
        if (availability && open) {
            setBeginMorning(dayjs(morningSchedules[0], 'HH:mm'))
            setEndMorning(dayjs(morningSchedules[morningSchedules.length - 1], 'HH:mm'))
            setBeginAfternoon(dayjs(afternoonSchedules[0], 'HH:mm'))
            setEndAfternoon(dayjs(afternoonSchedules[afternoonSchedules.length - 1], 'HH:mm'))
            setDuration(calculateAppointmentInterval(availability[day]))
        }
        //eslint-disable-next-line
    }, [open])

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Math.min(240, Math.max(1, parseInt(event.target.value, 10)));
        setDuration(newValue);
    };

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setAlert("hidden")
        setOpen(false);
    }

    const saveSchedules = async () => {
        setAlert("hidden")
        setLoading(true)
        const morningSchedules = generateTimeSlots(beginMorning!.format("HH:mm"), endMorning!.format("HH:mm"), duration)
        const afternoonSchedules = generateTimeSlots(beginAfternoon!.format("HH:mm"), endAfternoon!.format("HH:mm"), duration)
        let resultSchedules: string[] = []
        if (morningActive) {
            resultSchedules = morningSchedules
        }
        if (afternoonActive) {
            resultSchedules = [...resultSchedules].concat(afternoonSchedules)
        }
        axios.put(`${backendEndpoint}/availability`, { ...availability, [day]: removeDuplicates(resultSchedules) }).then(() => {
            axios.get(`${backendEndpoint}/availability`).then((res) => {
                setAvailability(res.data)
                setLoading(false)
                setAlert("success")
            })
        }).catch(() => {
            setLoading(false)
            setAlert("error")
        })
    }

    return (
        <div>
            <button onClick={handleOpen} className="editItem">
                <EditIcon />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <button onClick={handleClose} className='editBoxClose'>
                        <CloseIcon />
                    </button>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Editar horario
                    </Typography>

                    <FormControlLabel
                        control={<Checkbox checked={morningActive} onChange={() => setMorningActive((prev) => !prev)} />}
                        label="Atención por la mañana"
                    />

                    <FormControlLabel
                        control={<Checkbox checked={afternoonActive} onChange={() => setAfternoonActive((prev) => !prev)} />}
                        label="Atención por la tarde"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {morningActive && <div className='center dayHourPickerContainer'>
                            <TimeField
                                label="Inicio por la MAÑANA"
                                value={beginMorning}
                                onChange={(newValue, { validationError }) => {
                                    setError(validationError ? true : false)
                                    setBeginMorning(newValue)
                                }}
                                ampm={false}
                                minTime={dayjs().set('hour', 5).set('minute', 59)}
                                maxTime={dayjs().set('hour', 11).set('minute', 0)}
                            />

                            <Divider orientation='vertical' flexItem />

                            <TimeField
                                label="Fin por la MAÑANA"
                                value={endMorning}
                                onChange={(newValue, { validationError }) => {
                                    setError(validationError ? true : false)
                                    setEndMorning(newValue)
                                }}
                                ampm={false}
                                minTime={dayjs().set('hour', 6).set('minute', 0)}
                                maxTime={dayjs().set('hour', 12).set('minute', 0)}
                            />
                        </div>}

                        {afternoonActive && <div className='center dayHourPickerContainer'>
                            <TimeField
                                label="Inicio por la TARDE"
                                value={beginAfternoon}
                                onChange={(newValue, { validationError }) => {
                                    setError(validationError ? true : false)
                                    setBeginAfternoon(newValue)
                                }}
                                ampm={false}
                                minTime={dayjs().set('hour', 11).set('minute', 59)}
                                maxTime={dayjs().set('hour', 22).set('minute', 0)}
                            />

                            <Divider orientation='vertical' flexItem />

                            <TimeField
                                label="Fin por la TARDE"
                                value={endAfternoon}
                                onChange={(newValue, { validationError }) => {
                                    setError(validationError ? true : false)
                                    setEndAfternoon(newValue)
                                }}
                                ampm={false}
                                minTime={dayjs().set('hour', 12).set('minute', 0)}
                                maxTime={dayjs().set('hour', 23).set('minute', 0)}
                            />
                        </div>}

                        {(morningActive || afternoonActive) && <TextField
                            label="Duración del turno en minutos"
                            type="number"
                            value={duration}
                            onChange={handleInputChange}
                            fullWidth
                            inputProps={{
                                min: 10,
                                max: 240,
                                step: 5,
                            }}
                        />}

                        {!error && <button className='addItem' onClick={saveSchedules}>
                            {!loading ? "Guardar" : <CircularProgress size={20} sx={{ color: "black" }} />}
                        </button>}

                        {alert !== "hidden" &&
                            <Alert sx={{ width: "100%" }} severity={alert as AlertColor}>
                                {alert === "success" ?
                                    "Configuración guardada con éxito."
                                    :
                                    "Hubo un error al guardar el cambio."}
                            </Alert>
                        }
                    </LocalizationProvider>
                </Box>
            </Modal>
        </div>
    );
}