import React, { useEffect } from "react";
import "./admin.css"
import { Divider } from "@mui/material";
import { ImageRow, NewsRow, TextRow } from "./utils/rows";
import { useConfig } from "../../context/AdminContext";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ConfigFile, INews } from "../../typings/ConfigFile";


const ContactEditor = () => {
    const { newConfig, setNewConfig, config } = useConfig()

    useEffect(() => {
        setNewConfig(config)
        //eslint-disable-next-line
    }, [])

    if (!newConfig) return <></>

    return (
        <div className="editorContainer">
            <img className="logoAdmin" src={config?.logoImage} alt="logoAdmin" />
            <div className="editorConfig">

                <div className="editorRow editorHeader">
                    <span className="editorRowItem">Sección</span>
                    <span className="editorRowItem">Vista previa</span>
                    <span className="editorRowItem">Acciones</span>
                </div>
                <Divider />
                < TextRow
                    name={"Dirección"}
                    text={newConfig.contact.address}
                    prop={"contact.address"}
                />
                <Divider />
                < TextRow
                    name={"Ciudad"}
                    text={newConfig.contact.city}
                    prop={"contact.city"}
                />
                <Divider />
                < TextRow
                    name={"Provincia"}
                    text={newConfig.contact.state}
                    prop={"contact.state"}
                />
                <Divider />
                < TextRow
                    name={"Teléfono"}
                    text={newConfig.contact.phone}
                    prop={"contact.phone"}
                />
                <Divider />
                < TextRow
                    name={"Correo"}
                    text={newConfig.contact.email}
                    prop={"contact.email"}
                />
                <Divider />
                < ImageRow
                    name={"Mapa"}
                    url={newConfig.contact.mapPoint}
                    prop={"contact.mapPoint"}
                    isSlider={false}
                />
                <Divider />
                < ImageRow
                    name={"Banner Nosotros"}
                    url={newConfig.banners.imageAboutUs}
                    prop={"banners.imageAboutUs"}
                    isSlider={false}
                />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <span>Sección Nosotros</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {newConfig.aboutUs.map(({ imgUrl, title, content }: INews, index: number) => (
                            <NewsRow
                                name={title}
                                url={imgUrl}
                                content={content}
                                prop={`aboutUs[${index}]`}
                                isSlider={true}
                            />
                        ))}
                        <div className="center">
                            <button className="addItem" onClick={() => {
                                const array = newConfig.aboutUs
                                array.push({
                                    title: `Item ${array.length + 1}`,
                                    imgUrl: "https://static.thenounproject.com/png/4974686-200.png",
                                    content: ""
                                })
                                setNewConfig((prev: any) => ({
                                    ...prev,
                                    aboutUs: array
                                } as ConfigFile))
                            }}>Agregar +</button>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Divider />
                < TextRow
                    name={"Instagram"}
                    text={newConfig.contact.instagram}
                    prop={"contact.instagram"}
                />
                <Divider />
                < TextRow
                    name={"Facebook"}
                    text={newConfig.contact.facebook}
                    prop={"contact.facebook"}
                />
            </div>
        </div >
    );
};

export default ContactEditor;
