import { useEffect, useState } from "react";
import { useConfig } from "../../context/AdminContext";
import AppointmentHeader from "../Appointment/AppoinmentHeader";
import Header from "../Header/Header";
import ArticlePreview from "./ArticlePreview";
import "./news.css";
import { INews } from "../../typings/ConfigFile";
import ReactMarkdown from "react-markdown";
import { Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import WpButton from "../FloatButtons/WpButton";

function processString(inputString: string): string {
    const stringWithoutAccents = inputString
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    const stringWithoutDoubleDots = stringWithoutAccents.replace(/:/g, '');
    const processedString = stringWithoutDoubleDots.replace(/\s+/g, '-');

    return processedString;
}

const News = () => {
    const { config } = useConfig();
    const [selected, setSelected] = useState<INews>();
    const navigate = useNavigate();

    const handleGoBack = () => {
        setSelected(undefined);
        navigate("/novedades");
    };

    useEffect(() => {
        const urlArticle = window.location.pathname.split("novedades/")[1];
        const foundArticle = urlArticle && config?.news.find((article) => processString(article.title) === urlArticle);
        if (foundArticle) {
            setSelected(foundArticle);
        }
    }, [config]);

    useEffect(() => {
        if (selected) {
            navigate(`/novedades/${processString(selected.title)}`);
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line
    }, [selected]);

    if (!config) return <></>;

    return (
        <>
            <Header />
            {selected ? (
                <div>
                    <div className="container">
                        <div className="line" />
                    </div>
                    <button className="buttonB" onClick={handleGoBack}>
                        <svg width="10" height="20" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.936702 13.058C0.352378 12.4707 0.354745 11.521 0.941991 10.9366L10.5117 1.41453C11.0989 0.830209 12.0487 0.832577 12.633 1.41982C13.2173 2.00707 13.215 2.95681 12.6277 3.54114L4.12132 12.0052L12.5854 20.5116C13.1697 21.0989 13.1674 22.0486 12.5801 22.633C11.9929 23.2173 11.0431 23.2149 10.4588 22.6277L0.936702 13.058ZM2.99626 13.5024L1.99626 13.4999L2.00374 10.5L3.00374 10.5024L2.99626 13.5024Z" fill="black" fillOpacity="0.7" />
                        </svg>
                        Volver
                    </button>
                    <div className="selectedContainer">
                        <h1 className="selectedTitle">{selected.title}</h1>
                        <img className="selectedImg" src={selected.imgUrl} alt="articleImage" />
                        <ReactMarkdown className="selectedContent">{selected.content}</ReactMarkdown>
                        <Divider flexItem sx={{ marginTop: "1rem" }} />
                        <div className="newsContainer">
                            {config.news.filter((article) => article.imgUrl !== selected.imgUrl).slice(0, 3).map((article) => (
                                <ArticlePreview article={article} setSelected={setSelected} />
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <AppointmentHeader
                        image={config.banners.imageNews}
                        text1="Noticias y"
                        text2="Novedades"
                    />
                    <div className="newsContainer">
                        {config.news.map((article) => (
                            <ArticlePreview article={article} setSelected={setSelected} />
                        ))}
                    </div>
                </div>
            )}
            <WpButton/>
        </>
    );
};

export default News;