import { useEffect, useState } from "react"
import { useConfig } from "../../context/AdminContext"
import { config } from "../../config";
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import ModalSignUp from "./modals/ModalSignUp";
import { CircularProgress } from "@mui/material";


const { backendEndpoint } = config

const AccessEditor = () => {
    const { setNewConfig, config, newConfig } = useConfig()
    const [users, setUsers] = useState<any[]>([])
    const [loading, setLoading] = useState("")

    useEffect(() => {
        setNewConfig(config)
        axios(`${backendEndpoint}/users`).then((res) => setUsers(res.data))
        //eslint-disable-next-line
    }, [])

    const deleteUser = (email: string) => {
        setLoading(email)
        axios.delete(`${backendEndpoint}/users?email=${email}`).then(() => {
            setLoading("")
            setUsers((prev) => prev.filter((user) => user.email !== email));
        }).catch(() => {
            setLoading("")
        });
    }

    if (!newConfig) return <></>

    return (
        <div className="editorContainer">
            <img className="logoAdmin" src={config?.logoImage} alt="logoAdmin" />
            <div className="editorConfig">
                <div className="editorRow editorHeader">
                    <span className="editorRowItem">Usuarios</span>
                    <span className="editorRowItem"></span>
                    <span className="editorRowItem">Acciones</span>
                </div>
                {users.map((user) => (
                    <div className="editorRow">
                        <span className="editorRowItem">{user.email}</span>
                        <span className="editorRowItem"></span>
                        <div className="editorRowItem buttonContainer">
                            <button onClick={() => deleteUser(user.email)} className="editItem">
                                {loading !== user.email ?
                                    <DeleteIcon />
                                    :
                                    <div className="center"><CircularProgress size={15} sx={{ color: "black" }} /></div>
                                }
                            </button>
                        </div>
                    </div>
                ))}
                <div className="center">
                    <ModalSignUp setUsers={setUsers} />
                </div>
            </div>
        </div>
    )
}

export default AccessEditor