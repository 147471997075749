import React from "react";
import Header from "./components/Header/Header";
import AppointmentCreator from "./components/Appointment/AppointmentCreator";
import { AppointmentProvider } from "./context/ApContext";
import WpButton from "./components/FloatButtons/WpButton";

const Appointment = () => {
    return (
        <>
            <AppointmentProvider>
                <Header />
                <AppointmentCreator />
                <WpButton/>
            </AppointmentProvider>
        </>
    )
}

export default Appointment