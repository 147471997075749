import Header from "../Header/Header";
import AppointmentHeader from "../Appointment/AppointmentHeader";
import { useConfig } from "../../context/AdminContext";
import Calendario from "./Calendario";
import { GestionProvider } from "../../context/GestionContext";
import { useEffect } from "react";
import axios from "axios";
import { config } from "../../config";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "@mui/material";
import RotateScreen from "../RotateScreen/RotateScreen";
import CreateAppointmentModal from "./CreatorModal";
import Footer from "../Footer/Footer";

const { backendEndpoint } = config

const GestionTurnos = () => {
  const { config, setAvailability } = useConfig();
  const { loginWithPopup, isAuthenticated } = useAuth0();
  const isTooSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    axios.get(`${backendEndpoint}/availability`).then((res) => {
      setAvailability(res.data)
    })
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    const footer = document.querySelector(".footer") as HTMLDivElement
    if (!footer) return
    if (isTooSmallScreen || !isAuthenticated) {
      footer.style.display = "none"
    } else {
      footer.style.display = "flex"
    }
  }, [isTooSmallScreen, isAuthenticated])

  return (
    <>
      {!isTooSmallScreen ?

        (isAuthenticated ?
          <>
            <Header />
            <AppointmentHeader
              image={config!.banners.imageAppointment}
              text1="Gestión de "
              text2="Turnos"
            />
            <GestionProvider>
              <CreateAppointmentModal />
              <Calendario />
            </GestionProvider>
          </>
          :
          <div className="overlayNoLogin center">
            <div className="containerNoLogin">
              <Link to={"/"}>
                <img className="logoAdmin" src={config?.logoImage} alt="logoAdmin" />
              </Link>
              <span>Para acceder a la vista de gestión, por favor inicie sesión.</span>
              <button className="addItem" onClick={() => loginWithPopup()}>Acceder</button>
            </div>
          </div>
        )
        :
        <RotateScreen />
      }
    </>
  );
};

export default GestionTurnos;