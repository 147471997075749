import React from "react";
import "./appointment.css";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ContactEmergencyOutlinedIcon from '@mui/icons-material/ContactEmergencyOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { useAppointment } from "../../context/ApContext";

const Step2 = () => {
    const { appointment, setAppointment, setStep } = useAppointment()

    if (!appointment) return <></>

    const handleChange = (e: any) => {
        if (e.target.name.includes("dni") && isNaN(Number(e.target.value ?? 0))) return;
        const secondLevel = e.target.name.includes(".")
        if (!secondLevel) {
            setAppointment({
                ...appointment,
                [e.target.name]: e.target.value,
            });
        } else {
            setAppointment({
                ...appointment,
                parentOrGuardianDetails: {
                    ...appointment.parentOrGuardianDetails,
                    [e.target.name.split(".")[1]]: e.target.value
                } as any
            });
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        setStep(prev => prev + 1)
    };

    return (
        <div className="formContainer">
            <form onSubmit={handleSubmit}>
                <div className="sectionForm">
                    <span className="formTitle">Datos del paciente</span>
                    <div className="inputGroup">
                        <span className="icon">
                            <PersonOutlineOutlinedIcon />
                        </span>
                        <input
                            type="text"
                            name="name"
                            value={appointment.name}
                            onChange={handleChange}
                            placeholder="Nombre/s"
                            required
                        />
                    </div>

                    <div className="inputGroup">
                        <span className="icon">
                            <ContactEmergencyOutlinedIcon />
                        </span>
                        <input
                            type="text"
                            name="lastName"
                            value={appointment.lastName}
                            onChange={handleChange}
                            placeholder="Apellido"
                            required
                        />
                    </div>

                    <div className="inputGroup">
                        <span className="icon">
                            <PinOutlinedIcon />
                        </span>
                        <input
                            type="number"
                            name="age"
                            value={appointment.age}
                            onChange={handleChange}
                            placeholder="Edad"
                            required
                        />
                    </div>

                    <div className="inputGroup">
                        <span className="icon">
                            <BadgeOutlinedIcon />
                        </span>
                        <input
                            type="text"
                            name="dni"
                            value={appointment.dni}
                            onChange={handleChange}
                            placeholder="DNI"
                            minLength={7}
                            maxLength={8}
                            pattern="[0-9A-Za-z]+"
                            title="El DNI debe tener entre 7 y 8 dígitos"
                            required
                        />
                    </div>

                    <div className="inputGroup">
                        <span className="icon">
                            <QuestionMarkOutlinedIcon />
                        </span>
                        <input
                            type="text"
                            name="reasonForConsultation"
                            value={appointment.reasonForConsultation}
                            onChange={handleChange}
                            placeholder="Motivo de consulta"
                            required
                        />
                    </div>

                    <div className="inputGroup">
                        <span className="icon">
                            <Diversity3OutlinedIcon />
                        </span>
                        <input
                            type="text"
                            name="socialWork"
                            value={appointment.socialWork}
                            onChange={handleChange}
                            placeholder="Obra Social"
                            required
                        />
                    </div>

                    {appointment.patientType !== "Niño/a o Adolescente" && <div className="inputGroup">
                        <span className="icon">
                            <PhoneOutlinedIcon />
                        </span>
                        <input
                            type="tel"
                            name="contactPhone"
                            value={appointment.contactPhone}
                            onChange={handleChange}
                            placeholder={appointment.type === "other" ? "Celular (opcional)" : "Celular"}
                            required={!(appointment.type === "other")}
                        />
                    </div>}

                    {appointment.patientType !== "Niño/a o Adolescente" && <div className="inputGroup">
                        <span className="icon">
                            <EmailOutlinedIcon />
                        </span>
                        <input
                            type="email"
                            name="contactEmail"
                            value={appointment.contactEmail}
                            onChange={handleChange}
                            placeholder={appointment.type === "other" ? "Email (opcional)" : "Email"}
                            required={!(appointment.type === "other")}
                        />
                    </div>}
                </div>

                {appointment.type === "other" &&
                    <div className="sectionForm">
                        <span className="formTitle">Datos del responsable</span>
                        <div className="inputGroup">
                            <span className="icon">
                                <PersonOutlineOutlinedIcon />
                            </span>
                            <input
                                type="text"
                                name="parentOrGuardianDetails.name"
                                value={appointment.parentOrGuardianDetails?.name}
                                onChange={handleChange}
                                placeholder="Nombre/s"
                                required
                            />
                        </div>

                        <div className="inputGroup">
                            <span className="icon">
                                <ContactEmergencyOutlinedIcon />
                            </span>
                            <input
                                type="text"
                                name="parentOrGuardianDetails.lastName"
                                value={appointment.parentOrGuardianDetails?.lastName}
                                onChange={handleChange}
                                placeholder="Apellido"
                                required
                            />
                        </div>

                        <div className="inputGroup">
                            <span className="icon">
                                <BadgeOutlinedIcon />
                            </span>
                            <input
                                type="text"
                                name="parentOrGuardianDetails.dni"
                                value={appointment.parentOrGuardianDetails?.dni}
                                onChange={handleChange}
                                placeholder="DNI"
                                minLength={7}
                                maxLength={8}
                                pattern="[0-9A-Za-z]+"
                                title="El DNI debe tener entre 7 y 8 dígitos"
                                required
                            />
                        </div>

                        <div className="inputGroup">
                            <span className="icon">
                                <PhoneOutlinedIcon />
                            </span>
                            <input
                                type="number"
                                name="parentOrGuardianDetails.contactPhone"
                                value={appointment.parentOrGuardianDetails?.contactPhone}
                                onChange={handleChange}
                                placeholder="Celular"
                            />
                        </div>

                        <div className="inputGroup">
                            <span className="icon">
                                <EmailOutlinedIcon />
                            </span>
                            <input
                                type="email"
                                name="parentOrGuardianDetails.contactEmail"
                                value={appointment.parentOrGuardianDetails?.contactEmail}
                                onChange={handleChange}
                                placeholder="Email"
                                required
                            />
                        </div>
                    </div>
                }

                <button type="submit" id="submitButton" style={{ display: "none" }}>
                    Continuar
                    <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.1997 10.9393C12.783 11.5251 12.7791 12.4749 12.1909 13.0607L2.60527 22.6065C2.01705 23.1923 1.0673 23.1923 0.483951 22.6065C-0.0994026 22.0207 -0.0954575 21.071 0.492763 20.4852L9.01329 12L0.563254 3.51479C-0.0200992 2.92901 -0.0161541 1.97927 0.572066 1.39349C1.16029 0.807708 2.11003 0.807708 2.69339 1.39349L12.1997 10.9393ZM9.00623 10.5H11.1408L11.1284 13.5H8.99377L9.00623 10.5Z" fill="white" />
                    </svg>
                </button>
            </form>
        </div>
    );
};

export default Step2;