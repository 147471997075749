import "./appointment.css"
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import axios from "axios";
import { config } from "../../config"
import { useConfig } from "../../context/AdminContext";
import { useAppointment } from "../../context/ApContext";
import { useEffect, useState } from "react";
import PaymentStep from "./PaymentStep";
import { BackButton, ConfirmButton } from "./utils/buttons";
import AppointmentHeader from "./AppoinmentHeader";
import { useNavigate } from "react-router-dom";
const { backendEndpoint } = config

const AppointmentCreator = () => {
    const { config } = useConfig()
    const { appointment, step, setStep, setAppointment } = useAppointment()
    const [disabledForm, setDisabledForm] = useState(true)
    const [isReschedule, setIsReschedule] = useState(false)
    const [paymentReady, setPaymentReady] = useState(false)
    const [queded, setQueded] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [step])


    useEffect(() => {
        if (localStorage.getItem("reschedule")) {
            setIsReschedule(true)
            setAppointment(JSON.parse(localStorage.getItem("reschedule")!))
            setStep(3)
        }
        return (() => {
            localStorage.removeItem("reschedule")
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        checkForm()
        //eslint-disable-next-line
    }, [appointment]);

    const checkForm = () => {
        setDisabledForm(false)
        let appCopy = { ...appointment, date: "default", hour: "default", canceled: true }
        if (appointment.type === "other" && (!appointment.contactEmail || !appointment.contactPhone)) {
            appCopy = { ...appCopy, contactEmail: "default", contactPhone: "default" }
        }
        console.log(Object.values(appCopy));

        const areAllFieldsFilled = Object.values(appCopy).every(value => value);
        let extraDataIsFilled = true
        if (appointment.type === "other") {
            extraDataIsFilled = Object.values(appCopy.parentOrGuardianDetails || {}).length >= 5 && Object.values(appCopy.parentOrGuardianDetails || {}).every(value => value);
        }
        console.log(areAllFieldsFilled);
        console.log(extraDataIsFilled);


        setDisabledForm(!areAllFieldsFilled || !extraDataIsFilled);
    }

    if (!config) return <></>

    const confirmReservation = () => {
        if (!isReschedule) {
            let mpButton = document.querySelector(".mpContainer button") as HTMLButtonElement | null
            mpButton?.click()
        } else {
            let oldAppointment = JSON.parse(localStorage.getItem("reschedule")!)
            axios.put(`${backendEndpoint}/appointment/update/`, {
                email: oldAppointment.email,
                date: oldAppointment.date,
                hour: oldAppointment.hour,
                newData: appointment
            })
                .then(() => {
                    navigate(`/reserva-exitosa`)
                }).catch((err) => {
                    console.error(err)
                })
        }
    }

    return (
        <div>
            <AppointmentHeader
                image={config.banners.imageAppointment}
                text1="Reserva de"
                text2="turno"
            />

            <div className="appointBoxContainer">
                {step === 0 && <Step0 />}
                {step === 1 && <Step1 />}
                {step === 2 && <Step2 />}
                {step === 3 && <Step3 />}
                {(step === 4 || step === 5) && <Step4 text={`${!isReschedule ? `Recuerde que para reservar este turno, se debe abonar una seña de $${config?.reservationPrice}` : "Recuerde llegar 10 minutos antes del horario para evitar posibles demoras en la atención."}`} />}
            </div>

            <div className="appointBoxButtons">
                {step !== 0 && step !== 5 && !isReschedule && <BackButton onClick={() => setStep((prev) => prev - 1)} />}

                {step === 2 &&
                    <ConfirmButton
                        text="Continuar"
                        onClick={() => {
                            (document.querySelector("#submitButton") as HTMLButtonElement)?.click()
                        }}
                        disabled={disabledForm}
                    />
                }

                {step === 3 && <ConfirmButton onClick={() => setStep((prev) => prev + 1)} text="Confirmar" disabled={appointment.date === "" || appointment.hour === ""} />}

                {step === 4 && <ConfirmButton text={`${paymentReady ? `${isReschedule ? "Confirmar" : "Ir a pagar"}` : (queded ? "Turno en cola..." : "Cargando...")}`} onClick={confirmReservation} disabled={!paymentReady} />}
            </div>

            {step === 4 && <div className="mpContainer"><PaymentStep setPaymentReady={setPaymentReady} setQueded={setQueded}></PaymentStep></div>}
        </div>
    )
}

export default AppointmentCreator