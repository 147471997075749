import React, { useState } from 'react';
import { Box, Modal, TextField, Alert, AlertColor, Typography } from '@mui/material';
import { style } from '../utils/modalStyle';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { config } from "../../../config";
import axios from 'axios';

const { backendEndpoint } = config

interface Props {
    setUsers: React.Dispatch<React.SetStateAction<any[]>>
}

const ModalSignUp = ({ setUsers }: Props) => {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ type: "hidden", msg: "" })
    const regexMail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const regexPass = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]).{8,}$/

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setAlert({ type: "hidden", msg: "" })
        setLoading(false)
        setOpen(false);
    };

    const handleTextField1Change = (event: any) => {
        setAlert({ type: "hidden", msg: "" })
        setEmail(event.target.value);
    };

    const handleTextField2Change = (event: any) => {
        setAlert({ type: "hidden", msg: "" })
        setPassword(event.target.value);
    };

    const handleSave = () => {
        setAlert({ type: "hidden", msg: "" })
        if (!regexMail.test(email)) {
            return setAlert({ type: "error", msg: "Formato de email incorrecto" })
        }
        if (!regexPass.test(password)) {
            return setAlert({ type: "error", msg: "La contraseña debe tener al menos 8 caracteres e incluir al menos una mayúscula, un número y un carácter especial" })
        }
        setLoading(true)
        axios.post(`${backendEndpoint}/users`, {
            email, password
        }).then(() => {
            setLoading(false)
            setAlert({ type: "success", msg: "Usuario creado con éxito" })
            axios(`${backendEndpoint}/users`).then((res) => setUsers(res.data))
        }).catch(() => {
            setLoading(false)
            setAlert({ type: "error", msg: "Hubo un error al guardar el usuario o puede que el usuario ya exista" })
        })
    };

    return (
        <div>
            <button className="addItem" onClick={handleOpen}>Agregar +</button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <button onClick={handleClose} className='editBoxClose'>
                        <CloseIcon />
                    </button>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Agregar nuevo usuario
                    </Typography>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={handleTextField1Change}
                        fullWidth
                        margin="normal"
                    />
                    <TextField
                        label="Contraseña"
                        value={password}
                        onChange={handleTextField2Change}
                        fullWidth
                        margin="normal"
                        type='password'
                    />
                    {alert.type !== "success" && <button className='addItem' onClick={handleSave}>
                        {!loading ? "Guardar" : <CircularProgress size={20} sx={{ color: "black" }} />}
                    </button>}

                    {alert.type !== "hidden" &&
                        <Alert sx={{ width: "100%", maxWidth: "400px" }} severity={alert.type as AlertColor}>
                            {alert.msg}
                        </Alert>
                    }
                </Box>
            </Modal>
        </div>
    );
};

export default ModalSignUp;
