import React, { useEffect, useState } from "react";
import "./day.css";
import axios from "axios";
import { config } from "../../../../config";
import { useGestion } from "../../../../context/GestionContext";
import CircularProgress from '@mui/material/CircularProgress';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx";

const { backendEndpoint } = config;

interface DayProps {
  currentDate: Date;
}

const Day: React.FC<DayProps> = ({ currentDate }) => {
  const [availability, setAvailability] = useState<string[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const { monthAppointments, setMonthAppointments } = useGestion();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const year = currentDate.getFullYear().toString();
  const dayOfWeek = currentDate.toLocaleString("en-US", { weekday: "long" });
  const dayOfMonth = currentDate.getDate().toString().padStart(2, "0");
  const { setDetail } = useGestion();

  useEffect(() => {
    setLoading(true);

    Promise.all([
      axios(`${backendEndpoint}/appointment/${month}${year}`),
      axios(`${backendEndpoint}/availability/`)
    ]).then(([appointmentsRes, availabilityRes]) => {
      setMonthAppointments(appointmentsRes.data);
      setAvailability(availabilityRes.data[dayOfWeek.toLowerCase()]);
      setLoading(false);
    });
    //eslint-disable-next-line
  }, [currentDate]);

  const isMorning = (hour: string) => {
    const hours = Number(hour.split(":")[0]);
    return hours < 12;
  };

  const seeDetails = (e: any, appointment: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    setDetail({ open: true, appointment: appointment });
  };

  const handleDownloadExcel = () => {
    const filteredAppointments = monthAppointments.filter(app => app.date === `${dayOfMonth}/${month}/${year}`);
    const data = filteredAppointments.map(app => [app.hour, `${app.name} ${app.lastName}`, app.dni , app.contactPhone , app.canceled ? "Si" : ""]);

    const ws = XLSX.utils.aoa_to_sheet([["Hora", "Paciente", "DNI", "Celular", "Cancelado"], ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Turnos del día");
    XLSX.writeFile(wb, `Turnos del día ${dayOfMonth}-${month}-${year}.xlsx`);
  };

  const hasMorningAvailability = availability?.some((hour) => isMorning(hour));
  const hasAfternoonAvailability = availability?.some((hour) => !isMorning(hour));

  return (
    <div className={`${!hasMorningAvailability && !hasAfternoonAvailability ? "" : ""}`}>
      {loading ? (
        <div className="circularProg">{<CircularProgress size={50} sx={{ color: "black", marginTop: "50px" }} />}</div>
      ) : monthAppointments.filter((app) => app.date === `${dayOfMonth}/${month}/${year}`).length ? (
        <>
          <div className="day-content">
            <div className="horarios-container">
              {(monthAppointments?.filter((app) => isMorning(app.hour) && app.date === `${dayOfMonth}/${month}/${year}`))?.map((filtered: any, index: number) => {
                const thisApp = monthAppointments.find((app) => app._id === filtered._id);
                if (!thisApp) {
                  return <></>
                }
                return (
                  <div onClick={(e: any) => seeDetails(e, thisApp!)} key={`hour-${index}`} className={`cardDay ${thisApp?.canceled ? "canceled" : ""}`}>
                    <div style={{ fontWeight: 800 }}>Turno {thisApp.hour}</div>
                    <div>
                      {thisApp!.name} {thisApp!.lastName}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="horarios-container">
              {(monthAppointments?.filter((app) => !isMorning(app.hour) && app.date === `${dayOfMonth}/${month}/${year}`))?.map((filtered: any, index: number) => {
                const thisApp = monthAppointments.find((app) => app._id === filtered._id);
                if (!thisApp) {
                  return <></>
                }
                return (
                  <div onClick={(e: any) => seeDetails(e, thisApp!)} key={`hour-${index}`} className={`cardDay ${thisApp?.canceled ? "canceled" : ""}`}>
                    <div style={{ fontWeight: 800 }}>Turno {thisApp.hour}</div>
                    <div>
                      {thisApp!.name} {thisApp!.lastName}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="containerButtonExcel">
            <button className="downloadButtonExcel" onClick={handleDownloadExcel}>
              Descargar Planilla
              <SimCardDownloadIcon />
            </button>
          </div>
        </>
      ) : (
        <div className="noAppointments">
          No hay turnos para el día seleccionado
        </div>
      )}
    </div>
  );
}

export default Day;
