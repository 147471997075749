import "./appointment.css"

interface AppointmentHeaderProps {
    image: string;
    text1: string;
    text2: string;
}

const AppointmentHeader: React.FC<AppointmentHeaderProps> = ({
    image,
    text1,
    text2,
}) => {
    return (
        <div className="appointContainer">
            <img className="appointImg" src={image} alt="imageAppointment" />
            <div className="appointImgShadow"></div>

            <span className="appointImgText">
                {text1} <span style={{ fontWeight: "500" }}>{text2}</span>
            </span>
        </div>
    );
};

export default AppointmentHeader;
