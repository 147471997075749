import React, { useEffect, useState } from "react";
import Divider from '@mui/material/Divider';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import axios from "axios";
import { config } from "../../config";
import { useAppointment } from "../../context/ApContext";
import { CircularProgress } from "@mui/material";

const { backendEndpoint } = config;

dayjs.locale('es');

interface Schedules {
    unavailableSchedules: string[];
    allSchedules: string[];
}

const Step3 = () => {
    const thisMonth = dayjs().month() + 1;
    const nextTwoMonths = thisMonth + 2;
    const [schedules, setSchedules] = useState<Schedules | null>()
    const [clockLeft, setClockLeft] = useState<string[]>([])
    const [clockRight, setClockRight] = useState<string[]>([])
    const [availability, setAvailability] = useState<any>(null)
    const [gridDimension, setGridDimension] = useState(0)
    const [loading, setLoading] = useState(true)
    const { date, setDate, setAppointment, step } = useAppointment()

    const handleDate = async (newValue: any) => {
        setLoading(true);
        let formattedDate = newValue.format("DD-MM-YYYY").split("-").join("/");
        await axios(`${backendEndpoint}/availability/${formattedDate.split("/").join("")}`).then((res) => {
            setSchedules(res.data);
            const isMorning = (hour: string) => {
                const hours = Number(hour.split(":")[0]);
                return hours < 12;
            };
            const clockL = res?.data?.allSchedules?.filter((sch: string) => isMorning(sch));
            const clockR = res?.data?.allSchedules?.filter((sch: string) => !isMorning(sch));
            setClockLeft(clockL);
            setClockRight(clockR);
            const longest = clockL.length >= clockR.length ? clockL.length : clockR.length;
            setGridDimension(longest <= 12 ? 4 : 4 + Math.ceil((longest - 12) / 3));
        });
        setAppointment((prev) => ({
            ...prev,
            date: formattedDate,
        }));
        setDate(newValue);
        setLoading(false);
    };

    useEffect(() => {
        axios(`${backendEndpoint}/availability/`).then((res) => setAvailability(res.data));
        const today = dayjs();
        handleDate(today);
        setAppointment((prev) => ({
            ...prev,
            hour: "",
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const selectHour = (e: React.MouseEvent<HTMLDivElement>) => {
        const selectedHour = e.currentTarget.innerText;
        let clocks = document.querySelectorAll(".clockHour");
        for (let i = 0; i < clocks.length; i++) {
            const element = clocks[i] as HTMLDivElement;
            element.classList.remove("clockHourSelected");
        }
        e.currentTarget.classList.add("clockHourSelected");
        setAppointment((prev) => ({
            ...prev,
            hour: selectedHour,
        }));
    };

    return (
        availability ? (
            <div className="pickersBox">
                <div className="appointTitle">
                    Seleccione la <span>fecha</span> y la <span>hora</span>
                </div>
                <div className="pickersContainer">
                    <div className="calendarContainer">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                disablePast
                                value={date}
                                onChange={(newValue) => {
                                    !loading && handleDate(newValue);
                                }}
                                minDate={dayjs().date(1)}
                                maxDate={dayjs().date(1).add(nextTwoMonths, 'month').subtract(1, 'day')}
                                views={["day"]}
                                dayOfWeekFormatter={(_day, date) => {
                                    return dayjs(date).subtract(1, "day").locale("es").format('ddd').toUpperCase().slice(0, -1);
                                }}
                                shouldDisableDate={(day) => {
                                    const dayOfWeek = day.locale('en').format('dddd').toLowerCase();
                                    const isBannedDay = availability.bans.includes(day.format('DD/MM/YYYY'));
                                    return !availability[dayOfWeek].length || isBannedDay;
                                }}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="clockContainer">
                        <div className="clockTitle">Horarios disponibles</div>
                        <div className="clockDivisor">
                            {clockLeft?.length ? (
                                <div className="clockLeft" style={{ gridTemplateRows: `repeat(${gridDimension},1fr)` }}>
                                    {clockLeft?.map((elem, index) => (
                                        <div key={`ch-l-${index}`} className={"clockHour" + (schedules?.unavailableSchedules?.some((un) => un === elem) ? " clockDisabled" : "")} onClick={(e) => selectHour(e)}>
                                            {elem}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <></>
                            )}
                            {clockLeft?.length && clockRight?.length ? <Divider orientation="vertical" flexItem /> : <></>}
                            {clockRight?.length ? (
                                <div className="clockRight" style={{ gridTemplateRows: `repeat(${gridDimension},1fr)` }}>
                                    {clockRight?.map((elem, index) => (
                                        <div key={`ch-r-${index}`} className={"clockHour" + (schedules?.unavailableSchedules?.some((un) => un === elem) ? " clockDisabled" : "")} onClick={(e) => selectHour(e)}>
                                            {elem}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="center" style={{height:"20vh"}}>
                <CircularProgress size={40} sx={{ color: "black" }} />
            </div>
        )
    );
};

export default Step3;