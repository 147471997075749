import React, { useEffect } from "react";
import "./admin.css"
import { ConfigFile, Img } from "../../typings/ConfigFile"
import { Divider } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useConfig } from "../../context/AdminContext";
import { ImageRow, TextRow } from "./utils/rows";


const HomeEditor = () => {
    const { newConfig, setNewConfig, config } = useConfig()

    useEffect(() => {
        setNewConfig(config)
        //eslint-disable-next-line
    }, [])

    if (!newConfig) return <></>

    return (
        <div className="editorContainer">
            <img className="logoAdmin" src={config?.logoImage} alt="logoAdmin" />
            <div className="editorConfig">

                <div className="editorRow editorHeader">
                    <span className="editorRowItem">Sección</span>
                    <span className="editorRowItem">Vista previa</span>
                    <span className="editorRowItem">Acciones</span>
                </div>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <span> Carrousel de imagenes</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {newConfig.imgsCarrousel.map(({ url }: Img, index: number) => (
                            <ImageRow
                                name={`Imagen ${index+1}`}
                                url={url}
                                prop={`imgsCarrousel[${index}]`}
                                isSlider
                            />
                        ))}
                        <div className="center">
                            <button className="addItem" onClick={() => {
                                const array = newConfig.imgsCarrousel
                                array.push({
                                    name: `Imagen ${array.length + 1}`,
                                    url: "https://static.thenounproject.com/png/4974686-200.png"
                                })
                                setNewConfig((prev: any) => ({
                                    ...prev,
                                    imgsCarrousel: array
                                } as ConfigFile))
                            }}>Agregar +</button>
                        </div>
                    </AccordionDetails>
                </Accordion>
                <Divider />
                < ImageRow
                    name={"Logo"}
                    url={newConfig.logoImage}
                    prop={"logoImage"}
                    isSlider={false}
                />
                <Divider />
                < ImageRow
                    name={"Imagen de presentación"}
                    url={newConfig.imagePresentation}
                    prop={"imagePresentation"}
                    isSlider={false}
                />
                <Divider />
                < TextRow
                    name={"Encabezado"}
                    text={newConfig.presentationTitle}
                    prop={"presentationTitle"}
                    isSlider={false}
                />
                <Divider />
                < TextRow
                    name={"Texto información"}
                    text={newConfig.presentationText}
                    prop={"presentationText"}
                    isSlider={false}
                />
            </div>
        </div >
    );
};

export default HomeEditor;
