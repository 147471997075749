import * as React from 'react';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { config } from '../../../config';
import { useState } from 'react';
import { useConfig } from '../../../context/AdminContext';
import { ConfigFile } from '../../../typings/ConfigFile';
import { style } from '../utils/modalStyle';

const { backendEndpoint } = config;

interface Props {
    prop: string;
}

function getIndexFromProp(prop: string): number | null {
    const match = prop.match(/(\[\d+\])/);

    if (match) {
        const indexString = match[0].replace(/\D/g, '');
        return parseInt(indexString, 10);
    }

    return null;
}

export default function ModalDelete({ prop }: Props) {
    const [open, setOpen] = useState(false);
    const { setNewConfig, config, setConfig } = useConfig()
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const toDelete = Number(getIndexFromProp(prop)) + 1

    const handleDelete = () => {
        let newData = deleteNestedValue(config!, prop)
        axios.put(`${backendEndpoint}/`, newData).then((res) => {
            axios.get(`${backendEndpoint}/`).then((res) => {
                setConfig(res.data)
                setNewConfig(res.data)
            })
        }).catch((err) => console.error(err))
    }

    function deleteNestedValue(obj: ConfigFile, prop: string): ConfigFile {
        const propParts = prop.split('.');
        let currentLevel: any = obj;

        for (let i = 0; i < propParts.length - 1; i++) {
            const part = propParts[i];
            if (!currentLevel[part]) {
                currentLevel[part] = {};
            }
            currentLevel = currentLevel[part];
        }
        
        const lastPart = propParts[propParts.length - 1];
        const match = lastPart.match(/(\w+)(?:\[(\d+)\])?/);

        if (!match) {
            console.error('Invalid property format');
            return obj;
        }

        const propertyName = match[1];
        const index = match[2] ? Number(match[2]) : undefined;

        if (index !== undefined) {
            if (!Array.isArray(currentLevel[propertyName]) || isNaN(index) || index < 0) {
                console.error('Invalid array property or index');
                return obj;
            }
            currentLevel[propertyName].splice(index, 1);
        }

        return { ...obj };
    }

    return (
        <div>
            <button onClick={handleOpen} className="editItem">
                <DeleteIcon />
            </button>
            <Modal
                open={open}
            >
                <Box sx={style}>
                    <button onClick={handleClose} className='editBoxClose'>
                        <CloseIcon />
                    </button>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Vas a eliminar el elemento {toDelete}, ¿estás seguro/a?
                    </Typography>
                    <div className="modalDeleteActions">
                        <button className="cancelItem" onClick={handleClose}>Cancelar</button>
                        <button className="addItem" onClick={handleDelete}>Aceptar</button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}