
import { useReschedule } from "../../context/RescheduleContext"
import { Appointment } from "../../typings/Appointment"
import "../Appointment/appointment.css"
import { BackButton, ConfirmButton } from "../Appointment/utils/buttons"
import "./reschedule.css"
import Step0 from "./Step0"
import Step1 from "./Step1"
import Step2 from "./Step2"
import { config } from "../../config"
import axios from "axios"
import { useEffect } from "react"

const { backendEndpoint } = config;

const RescheduleBox = () => {
    const { setFound, setStep, step, dni } = useReschedule()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [step])

    const searchAppointments = () => {
        axios.post(`${backendEndpoint}/appointment/getByDni`, { dni }).then((res) => {
            setFound(res.data.filter((item: Appointment) => !item.canceled));
            setStep(1)
        }).catch(() => {
            setFound([]);
            setStep(1)
        })
    }
    return (
        <>
            <div className="appointContainer">
                <div className="appointBoxContainer">
                    {step === 0 && <Step0 />}
                    {step === 1 && <Step1 />}
                    {step === 2 && <Step2 />}
                    <div className="appointBoxButtons">
                        {step === 0 && dni &&
                            <ConfirmButton text="Continuar" onClick={searchAppointments} disabled={dni.length < 7}
                            />
                        }
                        {step === 1 && <BackButton onClick={() => setStep((prev) => prev - 1)} />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default RescheduleBox