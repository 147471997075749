import React, { ChangeEvent, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { ConfigFile } from '../../../typings/ConfigFile';
import { config } from '../../../config';
import "../admin.css"
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Alert, { AlertColor } from '@mui/material/Alert';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { useConfig } from '../../../context/AdminContext';
import { style } from '../utils/modalStyle';
import ImageIcon from '@mui/icons-material/Image';
const { backendEndpoint } = config;

interface Props {
    prop: string;
    text?: string;
    url?: string;
    isSlider?: boolean
    isNews?: boolean
}

export default function ModalEdit({ prop, text, url, isSlider, isNews }: Props) {
    const { newConfig, setNewConfig, setConfig } = useConfig()
    const [open, setOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [loading, setLoading] = useState(false)
    const isText = text || !url ? true : false
    const [textBox, setText] = useState<string>(text ?? "")
    const [alert, setAlert] = useState("hidden")
    const [src, setSrc] = useState(url)

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setAlert("hidden")
        setOpen(false)
    };

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedImage(file);
            const imageUrl = URL.createObjectURL(file);
            setSrc(imageUrl);
        }
    };

    const handleDrag = (event: React.DragEvent<HTMLLabelElement>) => {
        event.preventDefault();
        event.stopPropagation();
    }

    useEffect(() => {
        if (loading) {
            axios.put(`${backendEndpoint}/`, newConfig).then(() => {
                axios.get(`${backendEndpoint}/`).then((res) => {
                    setConfig(res.data)
                    setNewConfig(res.data)
                    setAlert("success")
                    setLoading(false)
                })
            }).catch(() => {
                setAlert("error")
                setLoading(false)
            })
        }
        //eslint-disable-next-line
    }, [newConfig])

    const handleUploadImage = async () => {
        if (selectedImage) {
            try {
                setAlert("hidden")
                setLoading(true)
                const formData = new FormData();
                formData.append('photo', selectedImage);

                const response = await fetch(`${backendEndpoint}/images`, {
                    method: 'POST',
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud POST: ${response.statusText}`);
                }

                const data = await response.json();
                setNewConfig((prev: ConfigFile | undefined) => {
                    if (!prev) {
                        return undefined;
                    }

                    const updatedConfig = { ...prev };
                    const propParts = prop.split('.');
                    let currentLevel: any = updatedConfig;

                    for (let i = 0; i < propParts.length - (isSlider ? 2 : 1); i++) {
                        currentLevel = currentLevel[propParts[i]];
                    }

                    if (isSlider) {
                        // Obtenemos la última parte de propParts y eliminamos los corchetes
                        const lastPart = propParts[propParts.length - 1];
                        const arrayIndexMatch = lastPart.match(/\[(\d+)\]/);

                        if (arrayIndexMatch) {
                            const arrayIndex = Number(arrayIndexMatch[1]);
                            const propertyName = lastPart.split('[')[0];

                            if (!isNaN(arrayIndex) && Array.isArray(currentLevel[propertyName]) && arrayIndex >= 0 && arrayIndex < currentLevel[propertyName].length) {
                                currentLevel[propertyName][arrayIndex] = !isNews ?
                                    {
                                        name: `Imagen ${arrayIndex + 1}`,
                                        url: data.url
                                    }
                                    :
                                    {
                                        ...currentLevel[propertyName][arrayIndex],
                                        imgUrl: data.url
                                    };
                            } else {
                                console.error('Invalid array index or property name for slider.');
                            }
                        } else {
                            console.error('Invalid array index syntax for slider.');
                        }
                    } else {
                        currentLevel[propParts[propParts.length - 1]] = data.url;
                    }

                    return updatedConfig;
                });
            } catch (error) {
                console.error(error);
            }
        } else {
            console.warn('Selecciona una imagen antes de subirla.');
        }
    };

    const handleTextChange = (prop: string, value: string) => {
        setNewConfig((prev: ConfigFile | undefined) => {
            const updatedConfig = { ...prev! };
            const propParts = prop.split('.');
            let currentLevel: any = updatedConfig;

            for (let i = 0; i < propParts.length - 1; i++) {
                currentLevel = currentLevel[propParts[i]];
            }

            currentLevel[propParts[propParts.length - 1]] = value;

            return updatedConfig;
        });
    };

    return (
        <div>
            <button onClick={handleOpen} className="editItem">
                {!isNews ? <EditIcon /> : <ImageIcon />}
            </button>
            <Modal
                open={open}
            >
                <Box sx={style}>
                    <button onClick={handleClose} className='editBoxClose'>
                        <CloseIcon />
                    </button>
                    {!isText ?
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Editar imagen
                            </Typography>
                            <img className='editBoxImage' src={src as string} alt="editImagePreview" />
                            <label
                                htmlFor="images"
                                className="drop-container"
                                id="dropcontainer"
                                onDragOver={handleDrag}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDrop={(event) => {
                                    handleDrag(event)
                                    const file = event.dataTransfer.files[0];
                                    if (file) {
                                        setSelectedImage(file);
                                        const imageUrl = URL.createObjectURL(file);
                                        setSrc(imageUrl);
                                    }
                                }}
                            >
                                <span className="drop-title">Arrastrar un archivo aquí</span>
                                o
                                <input type="file" id="images" accept="image/*" required onChange={handleImageChange} />
                            </label>
                            <button className='addItem' onClick={handleUploadImage}>
                                {!loading ? "Guardar" : <CircularProgress size={20} sx={{ color: "black" }} />}
                            </button>
                        </>
                        :
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Editar texto
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={10}
                                defaultValue={textBox}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setText(event.target.value);
                                }}
                                sx={{
                                    width: "100%",
                                    minWidth: "500px"
                                }}
                            />
                            <button className='addItem' onClick={() => {
                                setAlert("hidden")
                                setLoading(true)
                                handleTextChange(prop, textBox)
                            }}>
                                {!loading ? "Guardar" : <CircularProgress size={20} sx={{ color: "black" }} />}
                            </button>
                        </>}
                    {alert !== "hidden" &&
                        <Alert sx={{ width: "100%" }} severity={alert as AlertColor}>
                            {alert === "success" ?
                                "Configuración guardada con éxito."
                                :
                                "Hubo un error al guardar el cambio."}
                        </Alert>
                    }
                </Box>
            </Modal>
        </div>
    );
}