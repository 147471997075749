import React from "react";
import Header from "./components/Header/Header";
import RescheduleBox from "./components/Reschedule/RescheduleBox";
import { RescheduleProvider } from "./context/RescheduleContext";
import AppointmentHeader from "./components/Appointment/AppoinmentHeader";
import { useConfig } from "./context/AdminContext";
import WpButton from "./components/FloatButtons/WpButton";

const Reschedule = () => {
    const { config } = useConfig()

    if (!config) return <></>

    return (
        <>
            <RescheduleProvider>
                <Header />
                <AppointmentHeader
                    image={config.banners.imageReservations}
                    text1="Gestión de"
                    text2="Turnos"
                />
                <RescheduleBox />
                <WpButton/>
            </RescheduleProvider>
        </>
    )
}

export default Reschedule