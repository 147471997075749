import React from "react";
import Header from "./components/Header/Header";
import Carrousel from "./components/Carrousel/Carrousel";
import InfoSection from "./components/InfoSection/InfoSection";
import FloatButton from "./components/FloatButtons/FloatButton";
import { useConfig } from "./context/AdminContext";
import WpButton from "./components/FloatButtons/WpButton";

const Home = () => {
    const { config } = useConfig()
    if (!config) return <></>
    return (
        <>
            <Header />
            <Carrousel imgs={config.imgsCarrousel} />
            <InfoSection />
            <FloatButton />
            <WpButton/>
        </>
    )
}

export default Home