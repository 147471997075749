import React from "react";
import "./carrousel.css"
import { Img } from "../../typings/ConfigFile";
import Carousel from 'react-material-ui-carousel'

interface CarrouselProps {
    imgs: Img[]
}
interface ItemProps {
    item: Img;
}

const Carrousel = ({ imgs }: CarrouselProps) => {
    return (
        <Carousel
            navButtonsAlwaysVisible
            indicators={false}
        >
            {
                imgs.map((img, i) => <Item key={i} item={img} />)
            }
        </Carousel>
    )
}

const Item = ({ item }: ItemProps) => {
    return (
        <div style={{
            background: `linear-gradient(90deg, rgba(0, 0, 0, 0.40) 31%, rgba(0, 0, 0, 0.00) 100%), url(${item.url}) lightgray 50% / cover no-repeat`,
            width: '100%',  
            height: '100vh'
        }}
            className="sliderImage"
        />
    );
};


export default Carrousel