import * as React from 'react';
import { INews } from '../../typings/ConfigFile';
import "./news.css"


interface Props {
    article: INews
    setSelected: React.Dispatch<React.SetStateAction<INews | undefined>>
}

export default function ArticlePreview({ article, setSelected }: Props) {
    return (
        <div>
            <div className="articleImg" onClick={() => setSelected(article)} style={{
                background: `linear-gradient(90deg, rgba(0, 0, 0, 0.80) 0.01%, rgba(0, 0, 0, 0.00) 99.93%), url(${article.imgUrl}) lightgray 50% / cover no-repeat`,
            }}>
                <div>{article.title}</div>
            </div>
        </div>
    );
}