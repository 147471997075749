import React, { useEffect, useState } from 'react';
import { Appointment } from '../../typings/Appointment';
import { Modal, TextField, FormControl, Select, MenuItem, Box, CircularProgress, Alert, AlertColor } from '@mui/material';
import "./calendar.css"
import { style } from '../Admin/utils/modalStyle';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { config } from '../../config';

const { backendEndpoint } = config

const fields = [
    { label: 'Nombre', field: 'name' },
    { label: 'Apellido', field: 'lastName' },
    { label: 'Edad', field: 'age' },
    { label: 'DNI', field: 'dni' },
    { label: 'Motivo de Consulta', field: 'reasonForConsultation' },
    { label: 'Obra Social', field: 'socialWork' },
    { label: 'Teléfono de Contacto', field: 'contactPhone' },
    { label: 'Correo Electrónico de Contacto', field: 'contactEmail' },
    { label: 'Fecha', field: 'date' },
    { label: 'Hora', field: 'hour' },
    { label: 'Nombre del Padre/Tutor', field: 'parentOrGuardianDetails.name', withResponsible: true },
    { label: 'Apellido del Padre/Tutor', field: 'parentOrGuardianDetails.lastName', withResponsible: true },
    { label: 'DNI del Padre/Tutor', field: 'parentOrGuardianDetails.dni', withResponsible: true },
    { label: 'Teléfono de Contacto del Padre/Tutor', field: 'parentOrGuardianDetails.contactPhone', withResponsible: true },
    { label: 'Correo Electrónico de Contacto del Padre/Tutor', field: 'parentOrGuardianDetails.contactEmail', withResponsible: true },
];

const options = {
    "Niño/a o Adolescente": "Niño/a o Adolescente",
    "Adulto": "Adulto o Adulto Mayor",
    "Adulto / Para un familiar": "Adulto o Adulto Mayor"
}

const defaultAp = {
    patientType: '',
    type: '',
    name: '',
    lastName: '',
    age: '',
    dni: '',
    reasonForConsultation: '',
    socialWork: '',
    parentOrGuardianDetails: {
        name: '',
        lastName: '',
        dni: '',
        contactPhone: '',
        contactEmail: '',
    },
    date: '',
    hour: '',
    canceled: false
}


const CreateAppointmentModal: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [withResponsible, setWithResponsible] = useState(false);
    const [newAppointment, setNewAppointment] = useState<Appointment>(defaultAp);
    const [formFilled, setFormFilled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState("hidden")

    useEffect(() => {
        setFormFilled(!Object.values(newAppointment).some(e => e === ""))
    }, [newAppointment])

    const handleFieldChange = (field: string, value: string) => {
        setAlert("hidden")
        if (field === "patientType") {
            setWithResponsible(true)
            if (value === "Adulto") {
                setWithResponsible(false)
            }
            setNewAppointment(prevState => ({
                ...prevState,
                patientType: options[value as keyof typeof options],
                type: value === "Adulto" ? "personal" : "other"
            }))
        }
        if (field === "date" || field === "age" || field === "dni" || field === "contactPhone" || field === "hour" || field === "parentOrGuardianDetails.dni" || field === "parentOrGuardianDetails.contactPhone") {
            if (field === "hour" || field === "date") {
                const oldValue = newAppointment[field];
                if (field === "hour" && value.length === 2 && oldValue.length < value.length) {
                    value += ":"
                }
                if (field === "date" && (value.length === 2 || value.length === 5) && oldValue.length < value.length) {
                    value += "/"
                }
                if (field === "hour" && (value.length > 5 || isNaN(Number(value.split(":").join(""))))) return
                if (field === "date" && (value.length > 10 || isNaN(Number(value.split("/").join(""))))) return
            }
            else {
                if (isNaN(Number(value))) return;
            }
        }

        setNewAppointment(prevState => {
            const newState = { ...prevState };
            const fieldParts = field.split('.');

            if (fieldParts.length > 1) {
                let nestedObj: any = newState;
                for (let i = 0; i < fieldParts.length - 1; i++) {
                    nestedObj = nestedObj[fieldParts[i]];
                }
                nestedObj[fieldParts[fieldParts.length - 1]] = value;
            } else {
                newState[field as keyof Appointment] = value as never;
            }

            return newState;
        });
    };

    const onClose = () => {
        setNewAppointment(defaultAp)
        setAlert("hidden")
        setOpen(false);
    };

    const handleCreate = () => {
        setLoading(true)
        try {
            axios.post(`${backendEndpoint}/appointment/create`, newAppointment).then((res) => {
                setLoading(false)
                if (res.data.dbAp) {
                    setAlert("warning")
                } else {
                    setNewAppointment(defaultAp)
                    setAlert("success")
                }
            })
        } catch (error) {
            console.error("Error creating appointment:", error);
            setLoading(false)
            setAlert("error")
        }
    };

    return (
        <>
            <div className="center"><button className='createButton' onClick={() => setOpen(true)}>Crear un turno</button></div>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <button onClick={onClose} className='editBoxClose'>
                        <CloseIcon />
                    </button>
                    <h2>Crear un turno</h2>
                    <FormControl fullWidth>
                        <Select
                            value={newAppointment.patientType}
                            onChange={(e) => handleFieldChange('patientType', e.target.value as keyof typeof options)}
                            displayEmpty
                        >
                            <MenuItem value="" disabled>
                                Tipo de paciente
                            </MenuItem>
                            {Object.entries(options).map(([value]) => (
                                <MenuItem key={value} value={value}>{value}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {newAppointment?.patientType !== "" && <div className="createForm">
                        {fields.map((fieldObj, index) => (
                            (fieldObj.withResponsible && !withResponsible) ? null : (
                                <TextField
                                    key={index}
                                    label={fieldObj.label}
                                    value={getValueFromPath(newAppointment, fieldObj.field)}
                                    onChange={(e) => handleFieldChange(fieldObj.field, e.target.value)}
                                    fullWidth
                                />
                            )
                        ))}
                    </div>}
                    <button onClick={handleCreate} className={`addItem ${formFilled ? "" : "disabled"}`}>
                        {!loading ? "Guardar" : <CircularProgress size={20} sx={{ color: "black" }} />}
                    </button>
                    {alert !== "hidden" &&
                        <Alert sx={{ width: "100%" }} severity={alert as AlertColor}>
                            {alert === "success" ?
                                "Configuración guardada con éxito."
                                :
                                alert === "warning" ?
                                    "Ya existe un turno en este dia y horario."
                                    : "Hubo un error al guardar el cambio."}
                        </Alert>
                    }
                </Box>
            </Modal>
        </>
    );
};

function getValueFromPath(obj: any, path: string) {
    return path.split('.').reduce((acc, key) => acc[key], obj);
}

export default CreateAppointmentModal;
