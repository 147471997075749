import { useEffect } from "react"
import { useConfig } from "../../context/AdminContext"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { ImageRow, NewsRow } from "./utils/rows"
import { ConfigFile, INews } from "../../typings/ConfigFile"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NewsEditor = () => {
    const { setNewConfig, config, newConfig } = useConfig()

    useEffect(() => {
        setNewConfig(config)
        //eslint-disable-next-line
    }, [])

    if (!newConfig) return <></>

    return (
        <div className="editorContainer">
            <img className="logoAdmin" src={config?.logoImage} alt="logoAdmin" />
            <div className="editorConfig">
                <div className="editorRow editorHeader">
                    <span className="editorRowItem">Sección</span>
                    <span className="editorRowItem">Vista previa</span>
                    <span className="editorRowItem">Acciones</span>
                </div>
                < ImageRow
                    name={"Banner para novedades"}
                    url={newConfig.banners.imageNews}
                    prop={"banners.imageNews"}
                    isSlider={false}
                />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <span> Novedades</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {newConfig.news.map(({ imgUrl, title, content }: INews, index: number) => (
                            <NewsRow
                                name={title}
                                url={imgUrl}
                                content={content}
                                prop={`news[${index}]`}
                                isSlider={true}
                            />
                        ))}
                        <div className="center">
                            <button className="addItem" onClick={() => {
                                const array = newConfig.news
                                array.push({
                                    title: `Noticia ${array.length + 1}`,
                                    imgUrl: "https://static.thenounproject.com/png/4974686-200.png",
                                    content: ""
                                })
                                setNewConfig((prev: any) => ({
                                    ...prev,
                                    news: array
                                } as ConfigFile))
                            }}>Agregar +</button>
                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
        </div>
    )
}

export default NewsEditor