import React from "react";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import "./calendar.css";

interface NavigationButtonsProps {
  onPrevClick: () => void;
  onNextClick: () => void;
  currentMonth: string;
  isDayView: boolean;
  currentDate: Date;
}

function correctMiddleCapitalization(dateString: string): string {
  let words = dateString.split(" ").map((elem) => elem.charAt(0).toUpperCase() + elem.slice(1, elem.length).toLowerCase())
  return words.join(" ")
}


const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  onPrevClick,
  onNextClick,
  currentMonth,
  isDayView,
  currentDate,
}) => {
  return (
    <div className="Calendar-buttons">
      <button onClick={onPrevClick}>Anterior</button>
      {isDayView ? (
        <span>
          {correctMiddleCapitalization(format(currentDate, "EEEE d 'de' MMMM yyyy", {
            locale: es,
          }).replace(/(\b\w)/g, (match) => match.toUpperCase()))}
        </span>
      ) : (
        <span>{currentMonth}</span>
      )}
      <button onClick={onNextClick}>Siguiente</button>
    </div>
  );
};

export default NavigationButtons;
