import React, { SetStateAction, useEffect, useState } from "react";
import { initMercadoPago, Wallet } from "@mercadopago/sdk-react";
import axios from "axios";
import { useConfig } from "../../context/AdminContext";
import { config } from "../../config";
import { useAppointment } from "../../context/ApContext";

const { backendEndpoint } = config;

interface Props {
  setPaymentReady: React.Dispatch<SetStateAction<boolean>>;
  setQueded: React.Dispatch<SetStateAction<boolean>>;
}

const PaymentStep = ({ setPaymentReady, setQueded }: Props) => {
  const { config } = useConfig();
  const { appointment } = useAppointment();

  useEffect(() => {
    setPaymentReady(false)
    setQueded(false)
    handleBuy();
    //eslint-disable-next-line
  }, []);
  const [preferenceId, setPreferenceId] = useState(null);

  if (!config) return <></>;

  const price = config?.reservationPrice;

  const TOKEN = process.env.REACT_APP_MPTOKEN ?? "";

  initMercadoPago(TOKEN, { locale: "es-AR" });

  const createPreference = async () => {
    try {
      const response = await axios.post(
        `${backendEndpoint}/mercadopago/crear-preferencia`,
        {
          title: "Reserva",
          quantity: 1,
          price: price,
          appointment
        }
      );
      const { id, error } = response.data;
      if (error) {
        setQueded(true)
      }
      return id
    } catch (error) {
      console.error(error);
    }
  };

  const handleBuy = async () => {
    const id = await createPreference();
    if (id) {
      setPreferenceId(id);
      localStorage.setItem("appointment", JSON.stringify(appointment))
    }
  };


  return (
    <div id="wallet_container">
      {preferenceId && (
        <Wallet onReady={() => setPaymentReady(true)} initialization={{ preferenceId: preferenceId }} />
      )}
    </div>
  );
};

export default PaymentStep;
