import React, { useState, useEffect } from "react";
import NavigationButtons from "./NavegationButtons";
import Month from "./División/Month/Month";
import Week from "./División/Week/Week";
import Day from "./División/Day/Day";
import {
  format,
  addMonths,
  addWeeks,
  addDays,
  subWeeks,
  subDays,
  subMonths,
} from "date-fns";
import { es } from "date-fns/locale";
import ModalDetail from "./ModalDetail";
import { useGestion } from "../../context/GestionContext";

const Calendario = () => {
  const [selectedView, setSelectedView] = useState<string>("month");
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const { detail, setDetail } = useGestion();

  const minMonth = subMonths(new Date(), 1);
  const maxMonth = addMonths(new Date(), 2);

  const minWeek = subWeeks(new Date(), 2);
  const maxWeek = addWeeks(new Date(), 12);

  const handleViewChange = (view: string) => {
    setSelectedView(view);
  };

  const handleDayClick = (selectedDate: Date) => {
    setSelectedView("day");
    setCurrentDate(selectedDate);
  };

  const next = () => {
    setCurrentDate((prevDate) => {
      switch (selectedView) {
        case "month":
          const nextMonth = addMonths(prevDate, 1);
          return nextMonth > maxMonth ? prevDate : nextMonth;
        case "week":
          const nextWeek = addWeeks(prevDate, 1)
          return nextWeek > maxWeek ? prevDate : nextWeek;
        case "day":
          return addDays(prevDate, 1);
        default:
          return prevDate;
      }
    });
  };

  const prev = () => {
    setCurrentDate((prevDate) => {
      switch (selectedView) {
        case "month":
          const prevMonth = addMonths(prevDate, -1);
          return prevMonth < minMonth ? prevDate : prevMonth;
        case "week":
          const prevWeek = subWeeks(prevDate, 1)
          return prevWeek < minWeek ? prevDate : prevWeek;
        case "day":
          return subDays(prevDate, 1);
        default:
          return prevDate;
      }
    });
  };

  useEffect(() => {
    if (selectedView === "month") {
      setCurrentDate(new Date());
    } else if (selectedView === "week") {
      setCurrentDate(new Date())
    }
  }, [selectedView]);

  return (
    <>
      <div className="nav">
        <button
          className={selectedView === "month" ? "active" : ""}
          onClick={() => handleViewChange("month")}
        >
          Mes
        </button>
        <button
          className={selectedView === "week" ? "active" : ""}
          onClick={() => handleViewChange("week")}
        >
          Semana
        </button>
        <button
          className={selectedView === "day" ? "active" : ""}
          onClick={() => handleViewChange("day")}
        >
          Día
        </button>
      </div>

      <NavigationButtons
        onPrevClick={prev}
        onNextClick={next}
        currentMonth={format(currentDate, "MMMM yyyy", { locale: es }).replace(
          /(\b\w)/g,
          (match) => match.toUpperCase()
        )}
        isDayView={selectedView === "day"}
        currentDate={currentDate}
      />
      {detail?.appointment && (
        <ModalDetail
          appointment={detail.appointment}
          onClose={() => setDetail((prev: any) => ({ ...prev, open: false }))}
          open={detail.open}
        />
      )}
      {selectedView === "month" && (
        <Month currentDate={currentDate} onDayClick={handleDayClick} />
      )}
      {selectedView === "week" && (
        <Week currentDate={currentDate} onDayClick={handleDayClick}/>
      )}
      {selectedView === "day" && <Day currentDate={currentDate} />}
    </>
  );
};

export default Calendario;
