import React, { useEffect } from "react";
import "./admin.css"
import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DayRow, ImageRow, PriceRow } from "./utils/rows";
import { useConfig } from "../../context/AdminContext";
import axios from "axios";
import { config } from "../../config";
import { Availability } from "../../typings/Availability";
import ModalBans from "./modals/ModalBans";

const { backendEndpoint } = config

const AppointmentEditor = () => {
    const { newConfig, setNewConfig, config } = useConfig()
    const { availability, setAvailability } = useConfig()

    useEffect(() => {
        setNewConfig(config)
        axios.get(`${backendEndpoint}/availability`).then((res) => {
            setAvailability(res.data)
        })
        //eslint-disable-next-line
    }, [])

    if (!newConfig) return <></>

    return (
        <div className="editorContainer">
            <img className="logoAdmin" src={config?.logoImage} alt="logoAdmin" />
            <div className="editorConfig">

                <div className="editorRow editorHeader">
                    <span className="editorRowItem">Sección</span>
                    <span className="editorRowItem">Vista previa</span>
                    <span className="editorRowItem">Acciones</span>
                </div>
                <Divider />
                < ImageRow
                    name={"Banner para reservas"}
                    url={newConfig.banners.imageAppointment}
                    prop={"banners.imageAppointment"}
                    isSlider={false}
                />
                < ImageRow
                    name={"Banner para cancelación/gestión"}
                    url={newConfig.banners.imageReservations}
                    prop={"banners.imageReservations"}
                    isSlider={false}
                />
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <span> Horarios</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        {availability && Object.keys(availability).filter((key) => key.length > 4).map((day) => (
                            <DayRow day={day as keyof Availability} />
                        ))}
                        <div className="editorRow">
                            <span className="editorRowItem">Excepciones</span>
                            <span className="editorRowItem"></span>
                            <div className="editorRowItem buttonContainer">
                                <ModalBans />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
                < PriceRow
                    name={"Valor de la reserva"}
                    text={newConfig.reservationPrice}
                />
            </div>
        </div >
    );
};

export default AppointmentEditor;
