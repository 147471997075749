import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { style } from '../utils/modalStyle';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { Alert, AlertColor, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { config } from '../../../config';
import axios from 'axios';
import { useConfig } from '../../../context/AdminContext';
import { ConfigFile, INews } from '../../../typings/ConfigFile';
import CloseIcon from '@mui/icons-material/Close';


const { backendEndpoint } = config;

interface Props {
    name: string;
    content: string;
    prop: string;
}

export default function ModalEditNews({ name, content, prop }: Props) {
    const { newConfig, setNewConfig, setConfig } = useConfig()
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [alert, setAlert] = React.useState("hidden")
    const [title, setTitle] = React.useState("");
    const [textContent, setContent] = React.useState("");

    React.useEffect(() => {
        setTitle(name)
        setContent(content)
        //eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        if (loading) {
            axios.put(`${backendEndpoint}/`, newConfig).then(() => {
                axios.get(`${backendEndpoint}/`).then((res) => {
                    setConfig(res.data)
                    setNewConfig(res.data)
                    setAlert("success")
                    setLoading(false)
                })
            }).catch(() => {
                setAlert("error")
                setLoading(false)
            })
        }
        //eslint-disable-next-line
    }, [newConfig])

    const handleTextChange = () => {
        setNewConfig((prev) => {
            const [arrayName, indexStr] = prop.split('[');
            const index = parseInt(indexStr.replace(']', ''), 10);

            if (prev && prev[arrayName as keyof ConfigFile] && index >= 0 && index < (prev[arrayName as keyof ConfigFile] as INews[]).length) {
                const updatedConfig = { ...prev };

                (updatedConfig[arrayName as keyof ConfigFile] as INews[])[index] = {
                    ...(updatedConfig[arrayName as keyof ConfigFile] as INews[])[index],
                    title: title,
                    content: textContent
                };

                return updatedConfig;
            }
            return prev;
        });
    };


    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setAlert("hidden")
        setOpen(false)
    };


    return (
        <div>
            <button onClick={handleOpen} className="editItem">
                <TextFieldsIcon />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <button onClick={handleClose} className='editBoxClose'>
                        <CloseIcon />
                    </button>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Editar contenido
                    </Typography>
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={1}
                        defaultValue={title}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setTitle(event.target.value);
                        }}
                        sx={{
                            width: "100%",
                            minWidth: "500px"
                        }}
                    />

                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={10}
                        defaultValue={textContent}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setContent(event.target.value);
                        }}
                        sx={{
                            width: "100%",
                            minWidth: "500px"
                        }}
                    />

                    <button className='addItem' onClick={() => {
                        setAlert("hidden")
                        setLoading(true)
                        handleTextChange()
                    }}>
                        {!loading ? "Guardar" : <CircularProgress size={20} sx={{ color: "black" }} />}
                    </button>

                    {alert !== "hidden" &&
                        <Alert sx={{ width: "100%" }} severity={alert as AlertColor}>
                            {alert === "success" ?
                                "Configuración guardada con éxito."
                                :
                                "Hubo un error al guardar el cambio."}
                        </Alert>
                    }
                </Box>
            </Modal>
        </div>
    );
}