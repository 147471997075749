import React from "react";
import "./header.css"
import MenuTurnos from "./MenuTurnos";
import { Link, useLocation } from "react-router-dom";
import { useConfig } from "../../context/AdminContext";
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuDrawer from "./MenuDrawer";

const Header = () => {
    const { config } = useConfig()
    const isMobile = useMediaQuery('(max-width:1024px)');
    const location = useLocation()
    if (!config) return <></>

    return (
        <div className="headerContainer">
            <Link to={"/"}>
                <div className="logoHeader">
                    <img src={config.logoImage} alt="logoImage" />
                </div>
            </Link>

            {!isMobile ?
                <div className="botonHeaderCont">
                    {location.pathname === "/gestion-turnos" ? (
                        <button onClick={() => window.location.href = `${window.location.origin}/admin`}>
                            <button className="botonHeader">Admin</button>
                        </button>
                    ) : (
                        <>
                            <Link to={"/"}>
                                <button className={`botonHeader ${location.pathname === '/' ? 'active' : ''}`}>Inicio</button>
                            </Link>
                            <MenuTurnos/>
                            <Link to={"/novedades"}>
                                <button className={`botonHeader ${location.pathname === '/novedades' ? 'active' : ''}`}>Novedades</button>
                            </Link>
                            <Link to={"/nosotros"}>
                                <button className={`botonHeader ${location.pathname === '/nosotros' ? 'active' : ''}`}>Nosotros</button>
                            </Link>
                        </>
                    )}
                </div>
                :
                <MenuDrawer />
            }

            {/* {!isMobile ?
                <div className="botonHeaderCont">
                    <Link to={"/"}>
                        <button className="botonHeader">Inicio</button>
                    </Link>
                    <MenuTurnos />
                    <Link to={"/novedades"}>
                        <button className="botonHeader">Novedades</button>
                    </Link>
                    <Link to={"/nosotros"}>
                        <button className="botonHeader">Nosotros</button>
                    </Link>
                </div>
                :
                <MenuDrawer />
            }
 */}
        </div>
    )
}

export default Header