import React, { useEffect } from "react";
import { useAppointment } from "../../context/ApContext";


const Step1 = () => {
    const { setStep } = useAppointment()
    const { setAppointment, appointment } = useAppointment()

    useEffect(() => {
        if (appointment.patientType === "Niño/a o Adolescente") {
            setStep(0)
        }
        //eslint-disable-next-line
    }, [])

    return (
        <div className="step0Container step1">
            <div className="container">
                <span className="question">¿Su consulta es para <span style={{ fontWeight: 400 }}>usted</span> o para <span style={{ fontWeight: 400 }}>un familiar</span>?</span>
                <div className="buttonContainer">
                    <button onClick={() => {
                        setAppointment((prev) => ({ ...prev, type: "personal" }))
                        setStep(prev => prev + 1)
                    }}>Es para mí</button>
                    <button onClick={() => {
                        setAppointment((prev) => ({ ...prev, type: "other" }))
                        setStep(prev => prev + 1)
                    }}>Es para un familiar</button>
                </div>
            </div>
        </div>
    )
}

export default Step1