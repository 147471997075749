import React from "react";
import Header from "./components/Header/Header";
import About from "./components/AboutUs/About";
import AppointmentHeader from "./components/Appointment/AppoinmentHeader";
import { useConfig } from "./context/AdminContext";
import WpButton from "./components/FloatButtons/WpButton";

const AboutUs = () => {
    const { config } = useConfig()

    if (!config) return <></>

    return (
        <>
            <Header />
            <AppointmentHeader
                image={config.banners.imageAboutUs}
                text1=""
                text2="Misión, Visión y Valores"
            />
            <About />
            <WpButton/>
        </>
    )
}

export default AboutUs