import { InputAdornment, TextField } from "@mui/material"
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import { useReschedule } from "../../context/RescheduleContext";

const Step0 = () => {
    const { dni, setDni } = useReschedule()
    return (
        <>
            <div className="appointTitle">Ingrese el <span>DNI</span> del <span>paciente</span></div>
            <div className="dniContainer">
                <TextField
                    id="input-dni"
                    placeholder="DNI"
                    fullWidth
                    value={dni}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (isNaN(Number(event.target.value ?? 0))) return;
                        if (event.target.value.length > 8) return
                        setDni(event.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <BadgeOutlinedIcon />
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />
            </div>
        </>)
}

export default Step0