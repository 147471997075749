import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Menu from "./components/Admin/Menu";
import HomeEditor from "./components/Admin/HomeEditor";
import { Link, Route, Routes } from "react-router-dom";
import ContactEditor from "./components/Admin/ContactEditor";
import AppointmentEditor from "./components/Admin/AppointmentEditor";
import { useConfig } from "./context/AdminContext";
import "./components/Admin/admin.css"
import NewsEditor from "./components/Admin/NewsEditor";
import useMediaQuery from '@mui/material/useMediaQuery';
import HeaderMobile from "./components/Admin/HeaderMobile";
import RotateScreen from "./components/RotateScreen/RotateScreen";
import AccessEditor from "./components/Admin/AccessEditor";

const Admin = () => {
    const { loginWithPopup, isAuthenticated } = useAuth0();
    const { config } = useConfig()
    const isMobile = useMediaQuery('(max-width:1024px)');
    const isTooSmallScreen = useMediaQuery('(max-width:600px)');

    return (
        <div>
            {!isTooSmallScreen ? (isAuthenticated ? (
                <div style={{ display: "flex", flexDirection: `${!isMobile ? "row" : "column"}` }}>
                    {!isMobile ?
                        <Menu /> :
                        <HeaderMobile />
                    }
                    <Routes>
                        <Route path="/" element={<HomeEditor />} />
                        <Route path="/novedades" element={<NewsEditor />} />
                        <Route path="/nosotros" element={<ContactEditor />} />
                        <Route path="/turnos" element={<AppointmentEditor />} />
                        <Route path="/accesos" element={<AccessEditor />} />
                    </Routes>
                </div>
            ) : (
                <div className="overlayNoLogin center">
                    <div className="containerNoLogin">
                        <Link to={"/"}>
                            <img className="logoAdmin" src={config?.logoImage} alt="logoAdmin" />
                        </Link>
                        <span>Para acceder a la vista de administrador, por favor inicie sesión.</span>
                        <button className="addItem" onClick={() => loginWithPopup()}>Acceder</button>
                    </div>
                </div>
            ))
                :
                <RotateScreen />
            }
        </div>
    );
};

export default Admin;
