import React, { useState } from "react";
import "./admin.css"
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';

const Menu = () => {
    const { user, logout } = useAuth0();
    const [selected, setSelected] = useState(window.location.pathname)

    return (
        <div className="menuContainer">
            <div className="userContainer">
                <img src={user?.picture} alt="profileImage" />
                <span>{user?.name || user?.email}</span>
            </div>
            <Link to={"/admin"} onClick={() => setSelected("/admin")}>
                <button className={`menuButton ${selected === "/admin" ? "menuButtonSelected" : ""}`}>
                    Inicio
                </button>
            </Link>

            <Link to={"/admin/novedades"} onClick={() => setSelected("/admin/novedades")}>
                <button className={`menuButton ${selected === "/admin/novedades" ? "menuButtonSelected" : ""}`}>
                    Novedades
                </button>
            </Link>

            <Link to={"/admin/turnos"} onClick={() => setSelected("/admin/turnos")}>
                <button className={`menuButton ${selected === "/admin/turnos" ? "menuButtonSelected" : ""}`}>
                    Turnos
                </button>
            </Link>

            <Link to={"/admin/nosotros"} onClick={() => setSelected("/admin/nosotros")}>
                <button className={`menuButton ${selected === "/admin/nosotros" ? "menuButtonSelected" : ""}`}>
                    Nosotros
                </button>
            </Link>

            {localStorage.getItem("dev") && <Link to={"/admin/configuracion-avanzada"} onClick={() => setSelected("/admin/configuracion-avanzada")}>
                <button className={`menuButton ${selected === "/admin/configuracion-avanzada" ? "menuButtonSelected" : ""}`}>
                    Configuración Avanzada
                </button>
            </Link>}

            <Link to={"/gestion-turnos"}>
                <button className={`menuButton`}>
                    Gestión de turnos
                </button>
            </Link>

            <Link to={"/admin/accesos"} onClick={() => setSelected("/admin/accesos")}>
                <button className={`menuButton ${selected === "/admin/accesos" ? "menuButtonSelected" : ""}`}>
                    Accesos
                </button>
            </Link>

            <button
                className="logoutButton"
                onClick={() => logout()}>
                <KeyOutlinedIcon />
                Cerrar sesión
            </button>
        </div>
    );
};

export default Menu;
