import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { useConfig } from '../../context/AdminContext';
import "./admin.css"
import { useAuth0 } from '@auth0/auth0-react';

export default function HeaderMobile() {
    const [state, setState] = useState({ right: false });
    const { logout } = useAuth0();
    const { config } = useConfig()

    const options = [
        { path: "/admin", label: "Inicio" },
        { path: "/admin/novedades", label: "Novedades" },
        { path: "/admin/turnos", label: "Turnos" },
        { path: "/admin/nosotros", label: "Nosotros" }
    ]

    if (localStorage.getItem("dev")) {
        options.concat({ path: "/admin/configuracion-avanzada", label: "Configuración Avanzada" })
    }

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setState({ ...state, right: open });
    };

    const list = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <div className='drawerButtonClose'>
                <button>
                    <CloseIcon />
                </button>
            </div>
            <List>
                {options.map((item) => (
                    <Link to={item.path} key={item.path}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <button>
                                    {item.label}
                                </button>
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}

                <Link to="/gestion-turnos">
                    <ListItem disablePadding>
                        <ListItemButton>
                            <button>
                                Gestión de turnos
                            </button>
                        </ListItemButton>
                    </ListItem>
                </Link>

                <Link to="/admin/accesos">
                    <ListItem disablePadding>
                        <ListItemButton>
                            <button>
                                Accesos
                            </button>
                        </ListItemButton>
                    </ListItem>
                </Link>

                <ListItem disablePadding>
                    <ListItemButton>
                        <button
                            className='logoutMobile'
                            onClick={() => logout()}
                        >
                            {/* <KeyOutlinedIcon /> */}
                            Cerrar sesión
                        </button>
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );


    return (
        <div className='headerMobile'>
            <img className="logoAdminMobile" src={config?.logoImage} alt="logoAdmin" />
            <button style={{ width: "5rem", height: "5rem" }} onClick={toggleDrawer(true)}><MenuIcon /></button>
            <Drawer
                anchor="right"
                open={state.right}
                onClose={toggleDrawer(false)}
            >
                {list}
            </Drawer>
        </div>
    );
}
