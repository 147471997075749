import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { Appointment } from '../../typings/Appointment';
import "./calendar.css"
import printAppointment from '../Admin/utils/print';
import { useConfig } from '../../context/AdminContext';
import * as XLSX from "xlsx";

interface ModalProps {
    open: boolean;
    onClose: () => void;
    appointment: Appointment;
}

const Modal: React.FC<ModalProps> = ({ open, onClose, appointment }) => {
    const { config } = useConfig()

    const downloadExcel = () => {
        const appointmentData = [
            ['Tipo de Paciente', appointment.patientType],
            ['Tipo', appointment.type],
            ['Nombre', appointment.name],
            ['Apellido', appointment.lastName],
            ['Edad', appointment.age],
            ['DNI', appointment.dni],
            ['Razón de Consulta', appointment.reasonForConsultation],
            ['Obra Social', appointment.socialWork],
            ['Nombre del Tutor', appointment.parentOrGuardianDetails ? appointment.parentOrGuardianDetails.name : 'X'],
            ['Apellido del Tutor', appointment.parentOrGuardianDetails ? appointment.parentOrGuardianDetails.lastName : 'X'],
            ['DNI del Tutor', appointment.parentOrGuardianDetails ? appointment.parentOrGuardianDetails.dni : 'X'],
            ['Teléfono del Tutor', appointment.parentOrGuardianDetails ? appointment.parentOrGuardianDetails.contactPhone : 'X'],
            ['Correo Electrónico del Tutor', appointment.parentOrGuardianDetails ? appointment.parentOrGuardianDetails.contactEmail : 'X'],
            ['Teléfono de Contacto', appointment.contactPhone],
            ['Correo Electrónico de Contacto', appointment.contactEmail],
            ['Fecha', appointment.date],
            ['Hora', appointment.hour],
            ['Cancelado', appointment.canceled ? 'Sí' : 'No'],
        ];

        const ws = XLSX.utils.aoa_to_sheet(appointmentData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'AppointmentDetails');
        XLSX.writeFile(wb, `Turno de ${appointment.name}.xlsx`);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Detalles de la Cita</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <Typography variant="body1">
                        Tipo de Paciente: {appointment.patientType}
                    </Typography>
                    <Typography variant="body1">Tipo: {appointment.type}</Typography>
                    <Typography variant="body1">Nombre: {appointment.name}</Typography>
                    <Typography variant="body1">Apellido: {appointment.lastName}</Typography>
                    <Typography variant="body1">Edad: {appointment.age}</Typography>
                    <Typography variant="body1">DNI: {appointment.dni}</Typography>
                    <Typography variant="body1">
                        Razón de Consulta: {appointment.reasonForConsultation}
                    </Typography>
                    <Typography variant="body1">Obra Social: {appointment.socialWork}</Typography>

                    {appointment.parentOrGuardianDetails && (
                        <>
                            <Typography variant="body1">
                                Nombre del Tutor: {appointment.parentOrGuardianDetails.name}
                            </Typography>
                            <Typography variant="body1">
                                Apellido del Tutor: {appointment.parentOrGuardianDetails.lastName}
                            </Typography>
                            <Typography variant="body1">DNI del Tutor: {appointment.parentOrGuardianDetails.dni}</Typography>
                            <Typography variant="body1">
                                Teléfono del Tutor: {appointment.parentOrGuardianDetails.contactPhone}
                            </Typography>
                            <Typography variant="body1">
                                Correo Electrónico del Tutor: {appointment.parentOrGuardianDetails.contactEmail}
                            </Typography>
                        </>
                    )}

                    <Typography variant="body1">Teléfono de Contacto: {appointment.contactPhone}</Typography>
                    <Typography variant="body1">Correo Electrónico de Contacto: {appointment.contactEmail}</Typography>
                    <Typography variant="body1">Fecha: {appointment.date}</Typography>
                    <Typography variant="body1">Hora: {appointment.hour}</Typography>
                    <Typography variant="body1">Cancelado: {appointment.canceled ? 'Sí' : 'No'}</Typography>
                </DialogContentText>
                <div className='centerModalDetail'>
                    <button onClick={() => printAppointment(appointment, config!)} className='printButton'>Imprimir turno</button>
                    <button onClick={downloadExcel} className='printButton'>Descargar Planilla</button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default Modal;
