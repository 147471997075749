import { Appointment } from '../../../typings/Appointment';
import print from 'print-js';
import { ConfigFile } from '../../../typings/ConfigFile';

const printAppointment = (appointment: Appointment, config: ConfigFile) => {
  const container = document.createElement('div');
  container.id = 'printableId';

  const html = `
    <style>
      @media print {
        /* Ocultar encabezado y pie de página predeterminados */
        @page {
          margin: 0;
        }
        body {
          margin: 1cm;
        }
      }
      /* Estilos para el contenido */
      body {
        font-family: Arial;
      }
      .container {
        display: flex;
        justify-content: center;
        width: 100vw;
      }
      .logo {
        width: 250px;
      }
      .datos-turno {
        font-size: 16px;
      }
      .consulta {
        margin:1rem 0;
        text-align:center;
      }
    </style>
    <div class="container">
      <img class="logo" src="${config.logoImage}" alt="Logo"/>
    </div>
    <h1 class="datos-turno">Datos del turno</h1>
    <p class="datos-turno"><strong>Nombre:</strong> ${appointment.name}</p>
    <p class="datos-turno"><strong>Apellido:</strong> ${appointment.lastName}</p>
    <p class="datos-turno"><strong>Día:</strong> ${appointment.date}</p>
    <p class="datos-turno"><strong>Hora:</strong> ${appointment.hour}</p>
    <div class="consulta">
      Lo esperamos en ${config.contact.address}.
    </div>
    <div class="consulta">
      Recuerde llegar 10 minutos antes para evitar inconvenientes.
    </div>
    <div class="consulta">
      Por cualquier consulta no dude en llamarnos al ${config.contact.phone}.
    </div>
  `;

  container.innerHTML = html;

  document.body.appendChild(container);

  print({ printable: 'printableId', type: 'html' });

  document.body.removeChild(container);
};

export default printAppointment;
