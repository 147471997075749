import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Appointment } from '../typings/Appointment';

interface RescheduleContextProps {
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    found: Appointment[]
    setFound: React.Dispatch<React.SetStateAction<Appointment[]>>;
    canceled: Appointment | null;
    setCanceled: React.Dispatch<React.SetStateAction<Appointment | null>>;
    dni: string | null;
    setDni: React.Dispatch<React.SetStateAction<string | null>>;
}

const RescheduleContext = createContext<RescheduleContextProps | undefined>(undefined);

interface RescheduleProviderProps {
    children: ReactNode;
}

export const RescheduleProvider: React.FC<RescheduleProviderProps> = ({ children }) => {
    const [step, setStep] = useState(0)
    const [found, setFound] = useState<Appointment[]>([])
    const [canceled, setCanceled] = useState<Appointment | null>(null)
    const [dni, setDni] = useState<string | null>("")

    const contextValue: RescheduleContextProps = {
        step,
        setStep,
        found,
        setFound,
        canceled,
        setCanceled,
        dni,
        setDni
    };

    return (
        <RescheduleContext.Provider value={contextValue}>
            {children}
        </RescheduleContext.Provider>
    );
};

export const useReschedule = (): RescheduleContextProps => {
    const context = useContext(RescheduleContext);
    if (!context) {
        throw new Error('useReschedule debe usarse dentro de un RescheduleContextProvider');
    }
    return context;
};
