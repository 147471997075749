import React, { useEffect } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import axios from 'axios';
import Appointment from './Appointment';
import { config } from './config';
import Admin from './Admin';
import GestionTurnos from './components/GestionTurnos/Gestion';
import { useConfig } from './context/AdminContext';
import News from './components/News/News';
import Reschedule from './Reschedule';
import AboutUs from './AboutUs';
import Footer from './components/Footer/Footer';
import Success from './components/Appointment/AfterPayment/Success';
import Error from './components/Appointment/AfterPayment/Error';

const { backendEndpoint } = config;

function App() {
  const { config, setConfig } = useConfig()

  useEffect(() => {
    axios(backendEndpoint)
      .then((res) => setConfig(res.data))
      .catch((error) => console.error("error in fetch to config", error))
    //eslint-disable-next-line
  }, [])

  return (
    config ?
      <Auth0Provider
        domain={process.env.REACT_APP_AUTHDOMAIN ?? ""}
        clientId={process.env.REACT_APP_CLIENTID ?? ""}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/admin`
        }}
      >
        <div className='allAppContainer'>
          <div className='appContainer'>
            <Router>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/admin/*" element={<Admin />} />
                <Route path="/reservar" element={<Appointment />} />
                <Route path="/reprogramar" element={<Reschedule />} />
                <Route path="/novedades/*" element={<News />} />
                <Route path="/nosotros" element={<AboutUs />} />
                <Route path="/gestion-turnos" element={<GestionTurnos />} />
                <Route path="/reserva-exitosa" element={<Success />} />
                <Route path="/reserva-error" element={<Error />} />
              </Routes>
            </Router>
          </div>
          <div className='footerContainer'>
            {!window.location.pathname.includes('/admin') && <Footer />}
          </div>
        </div>
      </Auth0Provider>
      :
      <></>
  );
}

export default App;