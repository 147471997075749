import React, { useEffect, useState } from "react";
import "./week.css";
import {
  format,
  addDays,
  startOfWeek,
  eachDayOfInterval,
  isSameDay,
} from "date-fns";
import { useGestion } from "../../../../context/GestionContext";
import axios from "axios";
import { config } from "../../../../config";
import { Appointment } from "../../../../typings/Appointment";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import * as XLSX from "xlsx";

const { backendEndpoint } = config;

interface WeekProps {
  currentDate: Date;
  onDayClick: (selectedDate: Date) => void;
}

const Week: React.FC<WeekProps> = ({ currentDate, onDayClick }) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { monthAppointments, setMonthAppointments, setDetail } = useGestion();

  useEffect(() => {
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    axios(`${backendEndpoint}/appointment/${month}${year}`).then((res) => {
      setMonthAppointments(res.data);
    });
  }, [currentDate]);

  const renderWeekView = () => {
    const startOfWeekDate = startOfWeek(currentDate);
    const daysOfWeek = eachDayOfInterval({
      start: startOfWeekDate,
      end: addDays(startOfWeekDate, 6),
    });

    return (
      <div className="week-content">
        {daysOfWeek.map((day) => {
          const isToday = isSameDay(day, new Date());
          const isSelected = selectedDate && isSameDay(day, selectedDate);

          return (
            <div className="weekfcol">
              <span className="day">
                {day.toLocaleString("es-ES", { weekday: "long" })}
              </span>
              <div
                key={day.getTime()}
                className={`dayWeek ${isToday ? "today" : ""} ${isSelected ? "selected" : ""
                  }`}
                onClick={() => handleDayClick(day)}
              >
                <div>
                  <span>{format(day, "dd")}</span>
                </div>
                <div>
                  {monthAppointments
                    .filter((appo) => {
                      const appointmentDate = new Date(appo.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
                      return appointmentDate.getTime() === day.getTime();
                    })
                    .sort((a, b) => {
                      const timeA = a.hour.split(":").map(Number);
                      const timeB = b.hour.split(":").map(Number);

                      if (timeA[0] !== timeB[0]) {
                        return timeA[0] - timeB[0];
                      }

                      return timeA[1] - timeB[1];
                    })
                    .map((elem, index) => (
                      <div onClick={(e) => seeDetails(e, elem)} key={`ap-${index}`} className={`card ${elem?.canceled ? "canceled" : ""}`}>
                        <div style={{ fontWeight: 800 }}>Turno {elem.hour}</div>
                        <div>{elem.name} {elem.lastName}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleDayClick = (day: Date) => {
    setSelectedDate(day);
    onDayClick(day);
  };

  const seeDetails = (e: any, appointment: Appointment) => {
    e?.preventDefault();
    e?.stopPropagation();
    setDetail({ open: true, appointment: appointment });
  };

  const downloadExcel = () => {
    const startOfWeekDate = startOfWeek(currentDate);
    const daysOfWeek = eachDayOfInterval({
      start: startOfWeekDate,
      end: addDays(startOfWeekDate, 6),
    });
  
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([["Fecha", "Hora", "Paciente", "Cancelado"]]);
  
    let rowIndex = 1;
  
    XLSX.utils.sheet_add_aoa(worksheet, [["Fecha", "Hora", "Paciente", "DNI", "Contacto", "Cancelado"]], { origin: `A${rowIndex}` });
  
    rowIndex++;
  
    daysOfWeek.forEach((day, index) => {
      const appointmentsForDay = monthAppointments.filter(appo => {
        const appointmentDate = new Date(appo.date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3'));
        return isSameDay(appointmentDate, day);
      });
  
      appointmentsForDay.forEach((appointment) => {
        const rowData = [
          format(day, "yyyy-MM-dd"),
          appointment.hour,
          `${appointment.name} ${appointment.lastName}`,
          appointment.dni,
          appointment.contactPhone,
          appointment.canceled ? 'Si' : '',
        ];
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: `A${rowIndex}` });
        rowIndex++;
      });
    });
  
    XLSX.utils.book_append_sheet(workbook, worksheet, "Turnos de la Semana");
    XLSX.writeFile(workbook, "Turnos de la Semana.xlsx");
  };

  return (
    <div className="week">
      {renderWeekView()}
      <div className="center">
            <button className="downloadButton" onClick={downloadExcel}>
                Descargar Planilla
                <SimCardDownloadIcon/>
            </button>
        </div>
    </div>
  );
};

export default Week;